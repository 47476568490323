import { Button, Popconfirm } from "antd"

interface DownloadQuestionnaireButtonProps {
  allAnswersApproved: boolean
  onDownload: () => void
  disabled?: boolean
  loading: boolean
  downloading?: boolean
  getUnapprovedCount: () => number
}

const DownloadButton: React.FC<{
  disabled?: boolean
  loading: boolean
  downloading?: boolean
  onClick?: () => void
}> = ({ disabled, loading, downloading, onClick }) => (
  <Button
    className="text-primary flex h-9 w-32 items-center justify-center rounded-md bg-purple-50 px-4 font-bold"
    title="Download"
    onClick={onClick}
    disabled={disabled}
    loading={loading}
  >
    {downloading ? "Downloading..." : "Download"}
  </Button>
)

const DownloadQuestionnaireButton: React.FC<
  DownloadQuestionnaireButtonProps
> = ({
  allAnswersApproved,
  disabled,
  loading,
  onDownload,
  getUnapprovedCount,
  downloading,
}) => {
  return (
    <Popconfirm
      title="Answers Not Yet Approved"
      description={
        <>
          {getUnapprovedCount()} answers have not been approved by their owner.
          <br />
          Are you sure you want to continue downloading?
        </>
      }
      onConfirm={onDownload}
      okText="Yes"
      cancelText="No"
      disabled={allAnswersApproved || disabled}
    >
      <DownloadButton
        disabled={disabled}
        loading={loading}
        downloading={downloading}
        onClick={allAnswersApproved ? onDownload : undefined}
      />
    </Popconfirm>
  )
}

export default DownloadQuestionnaireButton
