import type { InternalRole } from "../groups/types.js"

export type QuiltPermission =
  // UserDocuments and KnowledgeItems.
  | "doc.read"
  | "doc.modify"
  // Read questionnaires.
  | "questionnaire.read"
  // Participate in questionnaires.
  | "questionnaire"
  // Search and get answers.
  | "search"
  // Broad permission for all group admin actions.
  | "admin"
  // Create a new user with random password
  | "createUser"
  // Add yourself to a group.
  | "addSelfToGroup"
  // Quilt employee access
  | "quiltAdmin"

export const RolePermissions: Record<InternalRole, QuiltPermission[]> = {
  QUILT_ADMIN: [
    "doc.read",
    "doc.modify",
    "questionnaire.read",
    "search",
    "admin",
    "quiltAdmin",
    "createUser",
  ],
  ADMIN: [
    "doc.read",
    "doc.modify",
    "questionnaire.read",
    "questionnaire",
    "search",
    "admin",
  ],
  MEMBER: [
    "doc.read",
    "doc.modify",
    "questionnaire.read",
    "questionnaire",
    "search",
  ],
  VIEWER: ["doc.read", "questionnaire.read", "questionnaire", "search"],
  CONTENT_MANAGER: ["doc.read", "doc.modify"],
  ANONYMOUS_SLACK_USER: ["addSelfToGroup", "createUser"],
} as const
