import { micromark } from "micromark"
import {
  gfmTaskListItem,
  gfmTaskListItemHtml,
} from "micromark-extension-gfm-task-list-item"

interface Props {
  content: string
  className?: string
}

const MarkdownRenderer: React.FC<Props> = ({ content, className = "" }) => {
  return (
    <div
      className={"markdown-container " + className}
      dangerouslySetInnerHTML={{
        __html: micromark(content, {
          extensions: [gfmTaskListItem()],
          htmlExtensions: [gfmTaskListItemHtml()],
        }),
      }}
    />
  )
}

export default MarkdownRenderer
