import Tag from "antd/es/tag"

import type { AnswerReference } from "../../types/answerer"
import { getReferenceTagColorClass } from "./index"

const ReferenceBadge: React.FC<{
  reference: AnswerReference
  isVerbatimFromAnswerBank?: boolean
}> = ({ reference, isVerbatimFromAnswerBank }) => (
  <Tag
    className="text-xs font-semibold"
    rootClassName={getReferenceTagColorClass(reference)}
  >
    {isVerbatimFromAnswerBank
      ? "Answer straight from answer bank"
      : reference.kind === "KNOWLEDGE_ITEM"
        ? "Answer Bank"
        : "Source Document"}
  </Tag>
)

export default ReferenceBadge
