import Button from "antd/es/button"
import Tooltip from "antd/es/tooltip"
import { Clipboard, ClipboardCheck } from "lucide-react"

import useCopyToClipboard from "../hooks/useCopyToClipboard"

interface Props {
  text: string
  height?: string | number
  className?: string
  customCopyTooltip?: string
}

const CopyToClipboardButton: React.FC<Props> = ({
  text,
  height,
  className,
  customCopyTooltip,
}) => {
  const [copied, copy] = useCopyToClipboard(text)

  className = "flex items-center justify-center h-full " + (className ?? "")
  return (
    <Tooltip
      title={
        copied ? "Copied!" : (customCopyTooltip ?? "Copy Link to Clipboard")
      }
    >
      <Button
        type="text"
        icon={
          copied ? (
            <ClipboardCheck height={height} />
          ) : (
            <Clipboard height={height} />
          )
        }
        className={className}
        onClick={copy}
      />
    </Tooltip>
  )
}

export default CopyToClipboardButton
