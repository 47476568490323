import { sendAnalyticsEvent } from "../../analytics"
import { createAnswerQuestionnaireJob } from "../../api"
import type { FileDataForUpload } from "../../documents/types"
import uploadUserFile from "../../files/uploadUserFile"

//TODO (ishan) maybe pull this api wrapper and the api.ts file into a jobs module
// so component doesnt have to import from the page module

export const createJob = async (fileData: FileDataForUpload) => {
  const { id: uploadId, uri: objectUri } = await uploadUserFile(
    "answer_questionnaire_job_uploads",
    fileData,
  )

  await createAnswerQuestionnaireJob({
    idempotency_key: uploadId,
    question_answer_layouts: fileData.question_answer_layouts,
    internal_document_uri: objectUri,
    mimetype: fileData.fileType,
    original_filename: fileData.fileName,
    title: fileData.fileName,
  })

  sendAnalyticsEvent({
    event_type: "SUBMIT_QUESTIONNAIRE",
    surface: "WEB_QUESTIONNAIRE_ASSISTANT",
    event_data: {
      entity_type: "ANSWER_QUESTIONNAIRE_JOB",
      entity_id: null,
    },
  })
}
