import Button from "antd/es/button"
import Form from "antd/es/form"
import Input from "antd/es/input"
import Popover from "antd/es/popover"
import { doc, updateDoc } from "firebase/firestore"
import { useCallback, useState } from "react"
import { useNavigate } from "react-router-dom"

import { makeConverter } from "../../dbUtils"
import { db } from "../../firebaseApp"
import { useKeydown } from "../../hooks/events"
import useErrorPopup from "../../hooks/useErrorPopup"
import { deleteLiveAssistedCall } from "../../live/api"
import { LIVE_ASSISTED_CALLS_COLLECTION } from "../../live/db"
import type { LiveAssistedCall } from "../../live/types"

interface FormData {
  title: string
}

interface FormProps {
  call: LiveAssistedCall | undefined
  onSubmit: () => void
}

const ModifyCallForm: React.FC<FormProps> = ({ call, onSubmit }) => {
  const [form] = Form.useForm<FormData>()
  const [submitting, setSubmitting] = useState<boolean>(false)
  const [removing, setRemoving] = useState<boolean>(false)
  const { handleSuccess, handleError } = useErrorPopup()
  const navigate = useNavigate()

  const removeCall = useCallback(async () => {
    if (!call?.oid) {
      return
    }
    setRemoving(true)
    try {
      await deleteLiveAssistedCall(call)
      navigate("/live-assistant")
    } catch (error) {
      handleError({ error, prefix: "Error removing call" })
    } finally {
      setRemoving(false)
    }
  }, [handleError, call, navigate])

  const onFinish = useCallback(
    async (formData: FormData) => {
      if (!call?.oid) {
        return
      }

      setSubmitting(true)
      try {
        await updateDoc(
          doc(db, LIVE_ASSISTED_CALLS_COLLECTION, call.oid).withConverter(
            makeConverter<LiveAssistedCall>(),
          ),
          formData,
        )
        handleSuccess("Updated call")
        onSubmit()
      } catch (error) {
        handleError({ error, prefix: "Couldn't save group" })
      } finally {
        setSubmitting(false)
      }
    },
    [handleSuccess, handleError, onSubmit, call?.oid],
  )

  if (!call) {
    return null
  }

  const initialValues = {
    title: call.title,
  }

  return (
    <Form
      form={form}
      layout="vertical"
      className=""
      disabled={submitting}
      style={{ width: 800 }}
      requiredMark="optional"
      initialValues={initialValues}
      onFinish={onFinish}
    >
      <Form.Item label="Call Title" name="title">
        <Input />
      </Form.Item>
      <div className="flex gap-2">
        <Button type="primary" disabled={submitting} htmlType="submit">
          {submitting ? "Saving..." : "Save"}
        </Button>
        <Button
          type="default"
          danger
          disabled={submitting || removing}
          onClick={removeCall}
        >
          {removing ? "Removing..." : "Remove"}
        </Button>
      </div>
    </Form>
  )
}

interface Props {
  call: LiveAssistedCall | undefined
  loading: boolean
  className?: string
}

const ModifyCallButton: React.FC<Props> = ({ call, loading, className }) => {
  const [open, setOpen] = useState(false)
  const hide = useCallback(() => {
    setOpen(false)
  }, [setOpen])
  useKeydown(27 /*escape*/, hide)

  return (
    <Popover
      content={<ModifyCallForm onSubmit={hide} call={call} />}
      trigger="click"
      placement="bottomLeft"
      open={open}
      onOpenChange={setOpen}
    >
      <Button
        type="default"
        title="Modify Call"
        className={className}
        disabled={loading}
        loading={loading}
      >
        Modify Call
      </Button>
    </Popover>
  )
}

export default ModifyCallButton
