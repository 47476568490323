import type { TagsFilterPredicate } from "../search/types"

export const tagsListToFilterPredicate = (
  selectedTags: string[] | null | undefined,
  tagOperator: "AND" | "OR",
): TagsFilterPredicate | null => {
  if (!tagOperator) return null
  if (!selectedTags?.length) return null

  return selectedTags.slice(1).reduce<TagsFilterPredicate>(
    (acc, tag) => {
      return {
        kind: "BINARY",
        op: tagOperator,
        left: acc,
        right: { kind: "UNARY", value: tag },
      }
    },
    { kind: "UNARY", value: selectedTags[0] },
  )
}

export const filterPredicateToTagsList = (
  tagsFilter: TagsFilterPredicate | string | null | undefined,
): string[] => {
  if (tagsFilter == null) return []
  if (typeof tagsFilter === "string") return [tagsFilter]

  // Recursively add tags.
  if (tagsFilter.kind === "UNARY") {
    return [tagsFilter.value]
  }

  return [
    ...filterPredicateToTagsList(tagsFilter.left),
    ...filterPredicateToTagsList(tagsFilter.right),
  ]
}
