import App from "antd/es/app"
import { useCallback } from "react"

import { getErrorMessage } from "../errors"

// Returns a function that handles an error and displays a popup.
const useErrorPopup = () => {
  const { message: messageApi } = App.useApp()

  const handleError = useCallback(
    (errorContext: {
      error?: unknown
      prefix?: string
      message?: React.ReactNode
      duration?: number
    }): void => {
      const key = Math.random().toString(36)
      if (errorContext.message) {
        // Log here since we won't call getErrorMessage
        console.error(errorContext.message, errorContext.error)
      }
      void messageApi.open({
        type: "error",
        content: errorContext.message
          ? errorContext.message
          : getErrorMessage(errorContext),
        key,
        onClick: () => messageApi.destroy(key),
        duration: errorContext.duration,
      })
    },
    [messageApi],
  )

  const handleSuccess = useCallback(
    (message: string): void => {
      const key = Math.random().toString(36)
      void messageApi.open({
        type: "success",
        content: message,
        key,
        onClick: () => messageApi.destroy(key),
      })
    },
    [messageApi],
  )

  return { messageApi, handleSuccess, handleError }
}

export default useErrorPopup
