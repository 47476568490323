import Badge from "antd/es/badge"
import Button from "antd/es/button"
import Divider from "antd/es/divider"
import Popover from "antd/es/popover"
import type { User as AuthUser } from "firebase/auth"
import { collection, query, where } from "firebase/firestore"
import {
  BellIcon,
  CheckIcon,
  CopyIcon,
  UserIcon,
  UsersIcon,
} from "lucide-react"
import { useState } from "react"
import { useCollectionData } from "react-firebase-hooks/firestore"
import { Link } from "react-router-dom"

import { useActiveUserAuthorizationFromContext } from "../contexts/ActiveUserAuthorizationContext"
import { useActiveGroup } from "../contexts/useActiveGroup"
import { db } from "../firebaseApp"
import { type QuiltFlags } from "../groups/types"
import useCopyToClipboard from "../hooks/useCopyToClipboard"
import { USERS_COLLECTION } from "../user/types"
import { isQuiltInternalUser } from "../utils"
import OpenExtensionButton from "./OpenExtensionButton"
import SignOutButton from "./SignOutButton"

interface Props {
  label?: string
  flags: QuiltFlags
}

const LINK_CLASSNAME = "flex items-center hover:underline"

const CopyBearerTokenButton: React.FC<{ user: AuthUser }> = ({ user }) => {
  const [copied, copy] = useCopyToClipboard()

  return (
    <a
      role="button"
      className={LINK_CLASSNAME}
      onClick={async () => copy(await user.getIdToken())}
    >
      {copied ? (
        <CheckIcon className="h-4 w-4" />
      ) : (
        <CopyIcon className="h-4 w-4" />
      )}
      <span className="ml-2">Copy Bearer Token</span>
    </a>
  )
}

const CopyUidButton: React.FC<{ user: AuthUser }> = ({ user }) => {
  const [copied, copy] = useCopyToClipboard(user.uid)

  return (
    <a className={LINK_CLASSNAME} onClick={copy}>
      {copied ? (
        <CheckIcon className="h-4 w-4" />
      ) : (
        <CopyIcon className="h-4 w-4" />
      )}
      <span className="ml-2">Copy UID</span>
    </a>
  )
}

const CopyGroupOidButton: React.FC = () => {
  const { activeGroupOid } = useActiveUserAuthorizationFromContext()
  const [copied, copy] = useCopyToClipboard(activeGroupOid)

  return (
    <a className={LINK_CLASSNAME} onClick={copy}>
      {copied ? (
        <CheckIcon className="h-4 w-4" />
      ) : (
        <CopyIcon className="h-4 w-4" />
      )}
      <span className="ml-2">Copy Group OID</span>
    </a>
  )
}

const SidebarUserIconMenu: React.FC<Props> = ({ label }) => {
  const [open, setOpen] = useState(false)
  const { authUser, activeGroup, numGroups } = useActiveGroup()
  // TODO(mgraczyk): Error handling?
  const [notificationValues] = useCollectionData<unknown>(
    query(
      collection(db, USERS_COLLECTION, authUser.uid, "notifications"),
      where("seen", "==", false),
      // Cannot subscribe to aggregates in firestore.
    ),
    { initialValue: [] },
  )

  const popoverContent = (
    <div className="flex flex-col">
      <Link className={LINK_CLASSNAME} to="/profile">
        <UserIcon className="h-4 w-4" />
        <span className="ml-2">Profile</span>
      </Link>
      <Link className={LINK_CLASSNAME} to="/group">
        <UsersIcon className="h-4 w-4" />
        <span className="ml-2">Manage Group</span>
      </Link>
      <Link className={LINK_CLASSNAME} to="/notifications">
        <BellIcon className="h-4 w-4" />
        <span className="ml-2">Notifications</span>
        <Badge
          size="small"
          count={notificationValues ? notificationValues.length : 0}
        >
          <span className="ml-2"></span>
        </Badge>
      </Link>
      <OpenExtensionButton />
      {isQuiltInternalUser(authUser) && (
        <>
          <Divider className="my-4" />
          <CopyBearerTokenButton user={authUser} />
          <CopyUidButton user={authUser} />
          <CopyGroupOidButton />
        </>
      )}
      <Divider className="my-4" />
      <SignOutButton />
    </div>
  )
  const userName = authUser.displayName || authUser.email || "Q"

  let buttonContent
  if (authUser.photoURL) {
    buttonContent = (
      <img
        className="h-9 w-9 rounded-full"
        width="36"
        height="36"
        src={authUser.photoURL}
        alt={`Photo of ${userName}`}
      />
    )
  } else {
    buttonContent = (
      <div className="flex h-9 w-9 items-center justify-center rounded-full text-2xl font-semibold text-white">
        {userName.slice(0, 1)}
      </div>
    )
  }

  const groupImageURL = activeGroup?.image_url
  if (groupImageURL && numGroups > 0) {
    buttonContent = (
      <div className="relative">
        {buttonContent}
        <div className="absolute -bottom-1 -right-1 h-5 w-5 rounded-full bg-white p-0.5">
          <img
            src={groupImageURL}
            alt="Group"
            className="h-4 w-4 rounded-full object-cover"
          />
        </div>
      </div>
    )
  }

  return (
    <Popover
      content={popoverContent}
      trigger="click"
      placement="left"
      open={open}
      onOpenChange={setOpen}
      className="flex flex-row items-center"
    >
      <Button type="primary" className="mr-3 h-9 w-9 rounded-full">
        <Badge count={notificationValues ? notificationValues.length : 0}>
          {buttonContent}
        </Badge>
      </Button>
      <span>{label}</span>
    </Popover>
  )
}
export default SidebarUserIconMenu
