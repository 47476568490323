import { Timestamp } from "@firebase/firestore"
import { useCallback, useEffect, useRef, useState } from "react"
import type {
  CloseEvent,
  ErrorEvent,
  Event,
  Message,
} from "reconnecting-websocket"
import ReconnectingWebSocket from "reconnecting-websocket"
import { useUnmount } from "usehooks-ts"

import { getFirebaseAuthToken } from "../../api"
import { getLiveBackendUrl } from "../../api"
import type { LiveInputEvent, LiveUIEvent } from "../../live/types"
import type { LiveSessionConfig } from "../../live/types"
import type { PlayState } from "./useTranscriptProcessor"

type ErrorCallbackType = (error: Error, message: string) => void

interface UseWebSocketResult {
  sendOverWebSocket: (message: LiveInputEvent) => Promise<void>
  webSocketState: WebSocketState
}

/*
  Timestamp sent by the server does not keep the initial firestore.Timestamp
   format so we need to parse it
 */
const _parseTimestamp = (created_at: unknown): FirebaseFirestore.Timestamp => {
  return Timestamp.fromDate(
    new Date(created_at as string),
  ) as FirebaseFirestore.Timestamp
}

const _serializeTimestamp = (
  created_at: FirebaseFirestore.Timestamp,
): string => {
  return created_at.toDate().toISOString()
}

const _serializeAudioData = (event: LiveInputEvent): Message => {
  const created_at = _serializeTimestamp(event.created_at)
  return JSON.stringify({ ...event, created_at })
}

/*
  LiveTranscriptSegment has a field with a firestore.Timestamp field
 */
// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-parameters
const _parseWithCreatedAt = <T,>(data: unknown): T => {
  if (data !== null && typeof data === "object" && "created_at" in data) {
    const created_at = _parseTimestamp(data.created_at)
    return {
      ...data,
      created_at: created_at,
    } as T
  } else {
    throw Error("Missing created_at field")
  }
}

const _parseLiveEvent = (event: MessageEvent): LiveUIEvent => {
  const data = JSON.parse(event.data as string) as unknown
  if (
    typeof data === "object" &&
    data !== null &&
    "kind" in data &&
    "oid" in data &&
    typeof data.oid === "string" &&
    "created_at" in data
  ) {
    const created_at = _parseTimestamp(data.created_at)
    if (data.kind === "TRANSCRIPT_SEGMENT_UPDATE" && "segment" in data) {
      return {
        ...data,
        oid: data.oid,
        kind: data.kind,
        segment: _parseWithCreatedAt(data.segment),
        created_at: created_at,
      }
    } else if (data.kind === "LIVE_UI_CARD_UPDATE" && "card" in data) {
      return {
        ...data,
        oid: data.oid,
        kind: data.kind,
        card: _parseWithCreatedAt(data.card),
        created_at: created_at,
      }
    }
    return {
      ...data,
      oid: data.oid,
      created_at: created_at,
    } as LiveUIEvent
  } else {
    console.error("Error parsing event", data)
    throw Error("Event is missing kind or created_at fields")
  }
}

async function _createWebSocket(
  callOid: string,
  onOpen: (event: Event) => void,
  onClose: (event: CloseEvent) => void,
  onEvent: (event: LiveUIEvent) => void,
  onError: ErrorCallbackType,
  sessionConfig?: LiveSessionConfig,
): Promise<ReconnectingWebSocket> {
  const authToken = await getFirebaseAuthToken()
  if (!authToken) {
    throw new Error("Not signed in")
  }
  const outputUrl = `${getLiveBackendUrl()}/live_assistant/assisted_calls/${callOid}/transcript/events`
  console.log("Connecting to ws at ", outputUrl)

  // Strip padding because websocket protocols do not allow "="
  const sessionConfigString = window
    .btoa(JSON.stringify(sessionConfig ?? {}))
    .replaceAll("=", "")

  const outputSocket = new ReconnectingWebSocket(
    outputUrl,
    [
      "quilt-transcription",
      `authorization.${authToken}`,
      `session-config.${sessionConfigString}`,
    ],
    {
      minReconnectionDelay: 3000,
      maxReconnectionDelay: 20000,
    },
  )

  outputSocket.addEventListener("open", onOpen)
  outputSocket.addEventListener("close", onClose)
  outputSocket.addEventListener("error", (event: ErrorEvent) =>
    onError(event.error, event.message),
  )
  outputSocket.addEventListener("message", (event) => {
    try {
      const liveEvent = _parseLiveEvent(event)
      onEvent(liveEvent)
    } catch (err) {
      console.error(err)
    }
  })

  return outputSocket
}

export type WebSocketState = "open" | "closed"

/*
  Custom hook to handle the websocket connection and receive or send LiveEvent messages
 */
const useLiveWebSocket = (
  callOid: string,
  onEvent: (event: LiveUIEvent) => void,
  onError: ErrorCallbackType,
  playState: PlayState,
  sessionConfig?: LiveSessionConfig,
): UseWebSocketResult => {
  const webSocketRef = useRef<Promise<ReconnectingWebSocket>>()
  const [webSocketState, setWebSocketState] = useState<WebSocketState>("closed")

  const _create = useCallback(() => {
    if (webSocketRef.current !== undefined) {
      return
    }
    webSocketRef.current = _createWebSocket(
      callOid,
      () => {
        setWebSocketState("open")
      },
      () => {
        setWebSocketState("closed")
      },
      onEvent,
      onError,
      sessionConfig,
    )
  }, [callOid, onError, onEvent, sessionConfig])

  const teardown = async () => {
    const curr = webSocketRef.current
    if (curr === undefined) {
      return
    }
    webSocketRef.current = undefined
    const ws = await curr
    ws.close()
  }

  useUnmount(teardown)

  useEffect(() => {
    const _createOrTeardown = async () => {
      if (playState === "starting" || playState === "playing") {
        _create()
      } else if (playState === "stopped" || playState === "stopping") {
        await teardown()
      }
    }
    void _createOrTeardown()
  }, [_create, playState])

  const sendOverWebSocket = useCallback(async (event: LiveInputEvent) => {
    const ws = await webSocketRef.current
    if (!ws || ws.readyState !== ws.OPEN) {
      // Drop the message if not connected so that we don't enqueue too many.
      // TODO(mgraczyk): We may want to queue messages if we are reconnecting.
      return
    }

    ws.send(_serializeAudioData(event))
  }, [])

  return {
    sendOverWebSocket,
    webSocketState,
  }
}

export default useLiveWebSocket
