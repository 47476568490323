import Select from "antd/es/select"

import { ResponseStyle, responseStyles } from "../../types/sheets"
import MultipleChoiceEditorPopover from "./MultipleChoice"
import type { Action } from "./reducer"
import type { DocumentFields, DocumentSheet, Field } from "./state"
import { isActiveColumn } from "./uiUtils"

const styleNameMap = {
  DEFAULT: "Default",
  VERY_SHORT: "Very Short",
}

const fieldsOrdered: { fieldName: keyof DocumentFields; label: string }[] = [
  { fieldName: "primaryQuestion", label: "Question" },
  { fieldName: "secondaryQuestion", label: "Question Description (optional)" },
  {
    fieldName: "answerExplanation",
    label: "Answer, Short Form or Explanation",
  },
  { fieldName: "answerCategorical", label: "Answer, Multiple Choice" },
  { fieldName: "questionId", label: "Question ID (optional)" },
]

const Column: React.FC<{
  column: string
  idx: number
  documentIndex: number
  sheetIndex: number
  sheet: DocumentSheet
  fields: DocumentFields
  dispatch: React.Dispatch<Action>
  hideVerbositySelector: boolean
  existingMCQOptions: string[]
}> = ({
  column,
  idx,
  documentIndex,
  sheetIndex,
  sheet,
  fields,
  dispatch,
  hideVerbositySelector,
  existingMCQOptions,
}) => {
  const fieldValue = Object.entries(fields).find(
    ([, field]: [unknown, Field]) => field.value === column,
  )?.[0]
  const toShowVerbosityFilter =
    fieldValue === "answerExplanation" && !hideVerbositySelector
  const toShowMultipleChoiceInput = fieldValue === "answerCategorical"

  return (
    <th
      key={idx}
      className="bg-gray-25 m-0 w-48 border border-t-0 border-gray-100 p-0 font-bold uppercase text-gray-500"
    >
      <div className="flex p-2">
        <Select
          dropdownStyle={{ width: 270 }}
          dropdownRender={(menu) => (
            <div>
              <div className="p-2 text-base font-bold text-gray-700">
                Column type:
              </div>
              {menu}
            </div>
          )}
          allowClear={true}
          className={`${toShowVerbosityFilter ? "w-1/2" : "w-full"} px-1`}
          placeholder="Select a field to map"
          value={fieldValue}
          options={fieldsOrdered.map(({ fieldName, label }) => ({
            value: fieldName,
            label,
          }))}
          onChange={(field) => {
            if (field === undefined) {
              dispatch({
                type: "SET_RESPONSE_STYLE",
                documentIndex,
                sheetIndex,
                payload: ResponseStyle.DEFAULT,
              })
              let key: keyof DocumentFields
              for (key in fields) {
                const value = fields[key]
                if (value.value === column) {
                  dispatch({
                    type: "SECTION_VALUE",
                    documentIndex,
                    sheetIndex,
                    payload: {
                      key: key,
                      value: undefined,
                      index: undefined,
                    },
                  })
                }
              }
              return
            }

            if (
              field === "answerCategorical" &&
              existingMCQOptions.length > 0
            ) {
              // iashris: if the user has already set multiple choice options for other sheets, we auto-populate the options in the new sheet
              dispatch({
                type: "SET_MULTIPLE_CHOICES",
                documentIndex,
                sheetIndex,
                payload: existingMCQOptions,
              })
            }

            dispatch({
              type: "SECTION_VALUE",
              documentIndex,
              sheetIndex,
              payload: {
                key: field as keyof DocumentFields,
                value: String(column),
                index: idx + 1,
              },
            })
          }}
        />
        {toShowVerbosityFilter && (
          <Select
            allowClear={true}
            dropdownStyle={{ width: 200 }}
            dropdownRender={(menu) => (
              <div>
                <div className="p-2 text-base font-bold text-gray-700">
                  Answer Verbosity:
                </div>
                {menu}
              </div>
            )}
            className={`${toShowVerbosityFilter ? "w-1/2" : "w-full"} px-1`}
            placeholder="Select a field to map"
            value={sheet.responseStyle}
            options={responseStyles.map((style) => ({
              value: style,
              label: styleNameMap[style],
            }))}
            onChange={(style) => {
              dispatch({
                type: "SET_RESPONSE_STYLE",
                documentIndex,
                sheetIndex,
                payload: style ?? ResponseStyle.DEFAULT,
              })
            }}
          />
        )}
        {toShowMultipleChoiceInput && (
          <MultipleChoiceEditorPopover
            sheetIndex={sheetIndex}
            initialOptions={sheet.multipleChoices}
            onSave={(options: string[]) => {
              dispatch({
                type: "SET_MULTIPLE_CHOICES",
                documentIndex,
                sheetIndex,
                payload: options,
              })
            }}
          />
        )}
      </div>
      <div
        className={
          isActiveColumn(String(column), fields)
            ? "bg-purple-500 font-bold text-white"
            : ""
        }
      >
        {String(column)}
      </div>
    </th>
  )
}

export default Column
