import Button from "antd/es/button"
import Popconfirm from "antd/es/popconfirm"
import { StepForwardIcon } from "lucide-react"
import { useCallback, useState } from "react"
import { useNavigate } from "react-router-dom"

import useErrorPopup from "../../hooks/useErrorPopup"
import { endLiveAssistedCall } from "../../live/api"
import type { LiveAssistedCall } from "../../live/types"

interface Props {
  call: LiveAssistedCall | undefined
  loading: boolean
}

const ModifyCallButton: React.FC<Props> = ({ call, loading }) => {
  const { handleSuccess, handleError } = useErrorPopup()
  const [submitting, setSubmitting] = useState<boolean>(false)
  const navigate = useNavigate()

  const onEnd = useCallback(async () => {
    if (!call?.oid || loading) {
      return
    }
    setSubmitting(true)
    try {
      await endLiveAssistedCall(call.oid)
      handleSuccess("Ended call")
      navigate(`/calls/${call.oid}`)
    } catch (error) {
      handleError({ error, prefix: "Error ending call" })
    } finally {
      setSubmitting(false)
    }
  }, [call, loading, handleSuccess, navigate, handleError])

  const confirmText = (
    <>
      <div>
        You will see post call analysis and will not be able to restart the
        call.
      </div>
      <div>Are you sure?</div>
    </>
  )

  return (
    <Popconfirm title={confirmText} trigger="click" onConfirm={onEnd}>
      <Button
        type="default"
        title="End Call"
        disabled={loading || submitting || call?.ended}
        loading={loading}
      >
        <StepForwardIcon /> End Call
      </Button>
    </Popconfirm>
  )
}

export default ModifyCallButton
