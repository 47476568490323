import axios from "axios"
import type { AxiosInstance } from "axios"

import type { AnalyticsEventSurface } from "./analytics/types"
import { ANALYTICS_PRODUCT } from "./constants"
import { auth } from "./firebaseApp"
import type {
  AnswerReference,
  AnswerResponse,
  GetAnswerRequest,
  GetAnswerResponse,
} from "./types/answerer"
import type {
  QuestionnaireInfo,
  QuestionnaireKind,
} from "./types/questionnaires"
import type {
  QuestionAnswerLayoutForAnswering,
  SheetsRange,
} from "./types/sheets"
import type {
  UserDocument,
  UserDocumentCreationRequest,
} from "./types/userDocument"

function getPyBackendUrl(): string {
  let server_url: string | undefined
  if (import.meta.env.VITE_USE_LOCAL_API_SERVER) {
    server_url =
      import.meta.env.VITE_LOCAL_API_SERVER_URL || "http://localhost:8080"
  } else {
    server_url = import.meta.env.VITE_API_SERVER_URL
  }
  if (!server_url) {
    throw new Error("No py backend url found")
  }

  console.log("Using server url", server_url)
  return server_url
}

function getFunctionsBackendUrl(): string {
  let server_url: string | undefined
  if (
    import.meta.env.VITE_USE_LOCAL_FUNCTIONS_BACKEND ||
    // Preserve behavior with functions emulator.
    import.meta.env.VITE_USE_FIREBASE_EMULATORS ||
    import.meta.env.VITE_USE_FUNCTIONS_EMULATOR
  ) {
    server_url =
      import.meta.env.VITE_LOCAL_FUNCTIONS_BACKEND_URL ||
      "http://localhost:5002"
  } else {
    server_url = import.meta.env.VITE_FUNCTIONS_BACKEND_URL
  }
  if (!server_url) {
    throw new Error("No functions backend url found")
  }

  console.log("Using functions backend url", server_url)
  return server_url
}

export function getLiveBackendUrl(): string {
  let server_url: string | undefined
  if (import.meta.env.VITE_USE_LOCAL_API_SERVER) {
    server_url =
      import.meta.env.VITE_LOCAL_API_SERVER_URL || "http://localhost:8080"
  } else {
    server_url = import.meta.env.VITE_LIVE_SERVER_URL
  }
  if (!server_url) {
    throw new Error("No live backend url found")
  }

  return server_url
}

export async function getFirebaseAuthToken(): Promise<string | null> {
  const appUser = auth.currentUser
  if (appUser) {
    return await appUser.getIdToken()
  }
  return null
}

function createAxiosInstance(baseURL: string): AxiosInstance {
  const instance = axios.create({ baseURL })
  instance.interceptors.request.use(async (config) => {
    const authToken = await getFirebaseAuthToken()
    if (authToken) {
      config.headers.Authorization = `Bearer ${authToken}`
    } else if (!config.metadata?.authNotRequired) {
      throw new Error("Trying to make request but not signed in")
    }

    return config
  })
  return instance
}

export const pyBackendAxiosInstance = createAxiosInstance(getPyBackendUrl())
export const functionsAxiosInstance = createAxiosInstance(
  getFunctionsBackendUrl(),
)

export const getAnswerInSheet = async (request: {
  questionsLocation: SheetsRange | null
  detailsLocation: SheetsRange | null
  answersLocation: SheetsRange
  analyticsSurface: AnalyticsEventSurface
}): Promise<AnswerResponse[]> => {
  const {
    data: { responses },
  } = await pyBackendAxiosInstance.post<{ responses: AnswerResponse[] }>(
    "answer_in_sheet",
    {
      ...request,
      analyticsProduct: ANALYTICS_PRODUCT,
    },
  )
  return responses
}

export const getOrCreateQuestionnaireInfo = async (
  sheetId: string,
  kind: QuestionnaireKind,
): Promise<QuestionnaireInfo> => {
  const {
    data: { questionnaire },
  } = await pyBackendAxiosInstance.post<{ questionnaire: QuestionnaireInfo }>(
    "questionnaires",
    {
      external_document_id: sheetId,
      kind,
    },
  )
  return questionnaire
}

export const deleteQuestionnaireInfo = async (
  oid: string,
): Promise<boolean> => {
  const {
    data: { deleted },
  } = await pyBackendAxiosInstance.delete<{ deleted: boolean }>(
    `questionnaires/${oid}`,
  )
  return deleted
}

// Get an answer to a question using the user's previously indexed documents.
export const getAnswer = async (
  request: Omit<GetAnswerRequest, "analytics_product">,
): Promise<GetAnswerResponse> => {
  const { data } = await pyBackendAxiosInstance.post<GetAnswerResponse>(
    "answer",
    {
      ...request,
      analytics_product: ANALYTICS_PRODUCT,
    },
  )
  return data
}

interface GetReferenceSearchResultsRequest {
  query: {
    text: string
    // TODO(mgraczyk): Add options with validation/bounds.
    options?: undefined
  }
  analyticsSurface: AnalyticsEventSurface
}

interface GetReferenceSearchResultsResponse {
  references: AnswerReference[]
  request_id: string
}

// Get an answer to a question using the user's previously indexed documents.
export const getReferenceSearchResults = async (
  request: GetReferenceSearchResultsRequest,
): Promise<GetReferenceSearchResultsResponse> => {
  const { data } =
    await pyBackendAxiosInstance.post<GetReferenceSearchResultsResponse>(
      "/references/search",
      {
        ...request,
        analyticsProduct: ANALYTICS_PRODUCT,
      },
    )
  return data
}

interface ExternalAnswerQuestionnaireJobCreationRequest {
  idempotency_key: string
  question_answer_layouts: {
    [sheetName: string]: QuestionAnswerLayoutForAnswering
  }
  external_document_url: string
  original_filename: string
  title: string
}
interface UploadedAnswerQuestionnaireJobCreationRequest {
  idempotency_key: string
  question_answer_layouts?: {
    [sheetName: string]: QuestionAnswerLayoutForAnswering
  }
  internal_document_uri: string
  mimetype: string
  original_filename: string
  title: string
}

type AnswerQuestionnaireJobCreationRequest =
  | ExternalAnswerQuestionnaireJobCreationRequest
  | UploadedAnswerQuestionnaireJobCreationRequest

export const createAnswerQuestionnaireJob = async (
  request: AnswerQuestionnaireJobCreationRequest,
): Promise<void> => {
  await pyBackendAxiosInstance.post<unknown>(
    "/answer_questionnaire_jobs",
    request,
  )
}

export const processAnswerQuestionnaireJob = async (
  oid: string,
): Promise<void> => {
  await pyBackendAxiosInstance.post(`/answer_questionnaire_jobs/${oid}/process`)
}

export const createUserDocuments = async (
  documents: UserDocumentCreationRequest[],
): Promise<UserDocument[]> => {
  const { data } = await pyBackendAxiosInstance.post<{
    documents: UserDocument[]
  }>("/user_documents/create-many", {
    documents,
  })
  return data.documents
}
