import { pyBackendAxiosInstance } from "../api"
import type {
  LiveAssistedCall,
  LiveAssistedCallCreationRequest,
  TranscriptSearchResponse,
} from "./types"

interface CreateLiveAssistedCallResult {
  success: true
  live_assisted_call: LiveAssistedCall
}

export async function createLiveAssistedCall(
  request: LiveAssistedCallCreationRequest,
): Promise<LiveAssistedCall> {
  const result =
    await pyBackendAxiosInstance.post<CreateLiveAssistedCallResult>(
      "/live_assistant/assisted_calls",
      request,
    )
  return result.data.live_assisted_call
}

export async function deleteLiveAssistedCall(
  call: LiveAssistedCall,
): Promise<void> {
  await pyBackendAxiosInstance.post<CreateLiveAssistedCallResult>(
    `/live_assistant/assisted_calls/${call.oid}/delete`,
    {},
  )
}

export async function processCallOutput(callOutputOid: string): Promise<void> {
  await pyBackendAxiosInstance.post(`/call_output/${callOutputOid}/process`, {})
}

export async function endLiveAssistedCall(callOid: string): Promise<void> {
  await pyBackendAxiosInstance.post<CreateLiveAssistedCallResult>(
    `/live_assistant/assisted_calls/${callOid}/end`,
    {},
  )
}

export async function resetSimulatedLiveAssistedCall(
  callOid: string,
): Promise<void> {
  await pyBackendAxiosInstance.post<CreateLiveAssistedCallResult>(
    `/live_assistant/assisted_calls/${callOid}/reset_simulation`,
    {},
  )
}

interface SendChatToLiveAssistedCallResult {
  success: true
}

export async function sendChatToLiveAssistedCall(
  call: LiveAssistedCall,
  text: string,
): Promise<void> {
  await pyBackendAxiosInstance.post<SendChatToLiveAssistedCallResult>(
    `/live_assistant/assisted_calls/${call.oid}/chat/messages`,
    {
      text,
    },
  )
}

export async function getTextToSpeech(text: string): Promise<ArrayBuffer> {
  const result = await pyBackendAxiosInstance.post<ArrayBuffer>(
    `/live_assistant/text_to_speech`,
    { text },
    {
      responseType: "arraybuffer",
    },
  )
  return result.data
}

export async function getTranscriptSearchResults(
  callOid: string,
  query: string,
): Promise<TranscriptSearchResponse> {
  const result = await pyBackendAxiosInstance.post<{
    result: TranscriptSearchResponse
  }>(`/live_assistant/assisted_calls/${callOid}/transcript-search`, { query })
  return result.data.result
}
