import {
  Timestamp,
  collection,
  limit,
  onSnapshot,
  orderBy,
  query,
} from "firebase/firestore"
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react"

import type { AnalyticsEventSurface } from "../../analytics/types"
import { getAnswer } from "../../api"
import { deleteAllChatMessages, saveChatMessageAnswer } from "../../chat/api"
import {
  CHAT_SESSIONS_SUBCOLLECTION,
  type ChatMessage,
  type ChatSession,
  MESSAGES_SUBCOLLECTION,
  type SaveChatMessageAnswerResult,
} from "../../chat/types"
import { ANALYTICS_PRODUCT, EMPTY_ARRAY } from "../../constants"
import { useActiveUserAuthorizationFromContext } from "../../contexts/ActiveUserAuthorizationContext"
import { getUserFacingError } from "../../errors"
import { db } from "../../firebaseApp"
import type {
  AnswerResponse,
  GetAnswerRequest,
  PreviousMessage,
} from "../../types/answerer"
import {
  GROUPS_COLLECTION,
  GROUP_MEMBERSHIP_SUBCOLLECTION,
} from "../../types/common"
import { randomId, replaceMany, shorten, updateWhere } from "../../utils"
import useErrorPopup from "../useErrorPopup"
import { ChatSessionsContext } from "./sessionsProvider"
import type { ChatMessageResponse, ChatMessageUIState } from "./types"
import { createChatMessage, deleteChatMessage, mergeMessages } from "./utils"

type GetAnswerInput = Pick<GetAnswerRequest, "question" | "previous_messages">

function getPreviousMessages(
  messages: ChatMessageUIState[],
): PreviousMessage[] | null {
  if (
    !import.meta.env.VITE_SEND_PREVIOUS_MESSAGES_IN_CHAT ||
    messages.length === 0
  ) {
    return null
  }

  const previousMessages: PreviousMessage[] = []
  for (const message of messages) {
    if (message.answerLoading || message.error || !message.content.answer) {
      break
    }

    // TODO(mgraczyk): Skip inner messages in long message threads.

    previousMessages.push({
      role: "user",
      content: message.content.question.text,
    })

    // TODO(mgraczyk): Handle cases where we seek clarification.
    let content: string
    if (message.content.answer.primary_answer) {
      // TODO(mgraczyk): Include references here, or on the backend let the LLM
      // do its own search query.
      content = message.content.answer.primary_answer
    } else if (message.content.answer.confidence === 0) {
      content = "Unable to answer"
    } else {
      content = "No answer."
    }

    previousMessages.push({
      role: "assistant",
      content,
    })
  }
  return previousMessages
}

export interface ChatContextType {
  sessions: ChatSession[]
  selectedSession: ChatSession | null
  selectedSessionId: string
  setSelectedSessionId: (sessionId: string) => void
  selectNewSession: () => void
  sessionLoading: boolean
  deleteSession: (sessionId: string) => Promise<void>
  messages: ChatMessageUIState[]
  updateMessage: (
    sessionOid: string,
    messageOid: string,
    answerText: string,
    analyticsSurface: AnalyticsEventSurface,
  ) => Promise<SaveChatMessageAnswerResult>
  deleteMessage: (sessionOid: string, message: ChatMessage) => Promise<void>
  messagesLoading: boolean
  getAnswersWithMessages: (
    requests: GetAnswerInput[],
    getAnswers: (requests: GetAnswerInput[]) => Promise<AnswerResponse[]>,
    skipUpdateSession?: boolean,
  ) => Promise<void>
  createNewQuestionMessage: (question_text: string) => Promise<void>
  deleteAllMessages: (sessionOid: string) => Promise<void>
}

const throwError = () => {
  throw new Error("ChatContext not initialized")
}

const defaultContext: ChatContextType = {
  sessions: EMPTY_ARRAY,
  selectedSession: null,
  selectedSessionId: "",
  setSelectedSessionId: throwError,
  selectNewSession: throwError,
  sessionLoading: false,
  deleteSession: throwError,
  messages: EMPTY_ARRAY,
  updateMessage: throwError,
  deleteMessage: throwError,
  messagesLoading: false,
  getAnswersWithMessages: throwError,
  createNewQuestionMessage: throwError,
  deleteAllMessages: throwError,
}

export const ChatContext = createContext<ChatContextType>(defaultContext)

export const ChatProvider: React.FC<{
  children: React.ReactNode
}> = ({ children }) => {
  const { handleError } = useErrorPopup()
  const { authUser, activeGroupOid } = useActiveUserAuthorizationFromContext()
  const {
    sessions,
    selectedSessionId,
    setSelectedSessionId,
    selectNewSession,
    selectedSession,
    createSession,
    deleteSession: deleteSessionFromSessionContext,
    sessionsLoading,
  } = useContext(ChatSessionsContext)

  const [sessionMessagesLoaded, setSessionMessagesLoaded] = useState<
    Record<string, boolean>
  >({})
  const [sessionMessages, setSessionMessages] = useState<
    Record<string, ChatMessageUIState[]>
  >({})

  // Subscribe to messages for selected session.
  useEffect(() => {
    if (sessionsLoading) {
      // Don't load messages until the sessions are loaded to avoid showing
      // empty state before we know whether or not the session exists.
      return
    }

    if (!selectedSession?.oid) {
      // Session is either deleted or new.
      setSessionMessagesLoaded((t) => ({ ...t, [selectedSessionId]: true }))
      return
    }

    const q = query(
      collection(
        db,
        GROUPS_COLLECTION,
        activeGroupOid,
        GROUP_MEMBERSHIP_SUBCOLLECTION,
        authUser.uid,
        CHAT_SESSIONS_SUBCOLLECTION,
        selectedSessionId,
        MESSAGES_SUBCOLLECTION,
      ),
      orderBy("timestamp", "desc"),
      limit(100),
    )

    setSessionMessagesLoaded((t) => ({ ...t, [selectedSessionId]: false }))
    return onSnapshot(
      q,
      (snapshot) => {
        const newMessages = snapshot.docs.map(
          (doc) =>
            ({
              ...doc.data(),
              oid: doc.id,
            }) as ChatMessageUIState,
        )

        // Combine existing messages with new messages so that currently-loading
        // messages remain, but be careful to remove messages for other sessions
        // and duplicates.
        setSessionMessages((t: Record<string, ChatMessageUIState[]>) => ({
          ...t,
          [selectedSessionId]: mergeMessages([
            ...(t[selectedSessionId] ?? []).filter(
              (m) => m.answerLoading || m.error != null,
            ),
            ...newMessages,
          ]).sort((b, a) => b.timestamp._compareTo(a.timestamp)),
        }))
        setSessionMessagesLoaded((t) => ({ ...t, [selectedSessionId]: true }))
      },
      (error) => {
        // TODO(mgraczyk): Show load error.
        console.error(error)
        setSessionMessagesLoaded((t) => ({ ...t, [selectedSessionId]: true }))
      },
    )
  }, [
    activeGroupOid,
    authUser.uid,
    sessionsLoading,
    selectedSession?.oid,
    selectedSessionId,
  ])

  const deleteSession = useCallback(
    async (deletedSessionId: string) => {
      await deleteSessionFromSessionContext(deletedSessionId)
      setSessionMessages((t) => ({
        ...t,
        [deletedSessionId]: EMPTY_ARRAY,
      }))
    },
    [deleteSessionFromSessionContext],
  )

  const updateMessage = useCallback(
    async (
      sessionOid: string,
      messageOid: string,
      answerText: string,
      analyticsSurface: AnalyticsEventSurface,
    ) => {
      const response = await saveChatMessageAnswer({
        sessionOid,
        messageOid,
        answerUpdate: {
          primary_answer: answerText,
        },
        analyticsProduct: ANALYTICS_PRODUCT,
        analyticsSurface,
      })
      return response.data
    },
    [],
  )

  const deleteMessage = useCallback(
    async (sessionOid: string, message: ChatMessage) => {
      // In theory we need to check if the message is loading before deleting.
      // We can just let it fail and show an error, and try to disable the
      // button elsewhere to prevent this.
      try {
        setSessionMessages((t) => ({
          ...t,
          [sessionOid]: updateWhere(
            t[sessionOid] ?? [],
            (m) => ({ ...m, deleting: true }),
            (m) => m.oid == message.oid,
          ),
        }))
        await deleteChatMessage({
          groupOid: activeGroupOid,
          uid: authUser.uid,
          sessionOid,
          messageOid: message.oid,
        })

        // Remove because some messages are not in the database.
        setSessionMessages((t) => ({
          ...t,
          [sessionOid]: (t[sessionOid] ?? []).filter(
            (m) => m.oid !== message.oid,
          ),
        }))
      } catch (error) {
        handleError({ error, prefix: "Couldn't delete message" })
        setSessionMessages((t) => ({
          ...t,
          [sessionOid]: updateWhere(
            t[sessionOid] ?? [],
            (m) => ({ ...m, deleting: false }),
            (m) => m.oid == message.oid,
          ),
        }))
      }
    },
    [activeGroupOid, authUser.uid, handleError],
  )

  const deleteAllMessages = useCallback(
    async (sessionOid: string) => {
      try {
        setSessionMessages((t) => ({
          ...t,
          [sessionOid]: EMPTY_ARRAY,
        }))
        await deleteAllChatMessages({ sessionOid })
      } catch (error) {
        handleError({ error, prefix: "Couldn't delete messages" })
      }
    },
    [handleError],
  )

  // TODO(mgraczyk): Combine this with createNewQuestionMessage.
  const getAnswersWithMessages = useCallback(
    async (
      requests: GetAnswerInput[],
      getAnswers: (requests: GetAnswerInput[]) => Promise<AnswerResponse[]>,
      skipUpdateSession: boolean = false,
    ) => {
      if (requests.length === 0) {
        return
      }
      const mustCreateSession = !selectedSession
      const sessionId = selectedSessionId

      const now = Timestamp.now() as FirebaseFirestore.Timestamp
      const newChatMessages: ChatMessageUIState[] = requests.map(
        (request, i) => {
          const timestamp = new Timestamp(
            now.seconds,
            now.nanoseconds + i * 1000000,
          ) as FirebaseFirestore.Timestamp
          return {
            oid: randomId(),
            content: {
              question: request.question,
              answer: null,
              answererRequestId: null,
            },
            timestamp,
            updated_at: timestamp,
            answerLoading: true,
          }
        },
      )

      setSessionMessages((t: Record<string, ChatMessageUIState[]>) => ({
        ...t,
        [sessionId]: [...(t[sessionId] ?? []), ...newChatMessages],
      }))

      //TODO (ishan) separate out a type for "ChatResponse"
      let answerResponses: ChatMessageResponse[]
      let saveAnswerMessages: boolean
      try {
        // If there is no session yet, create one.
        // TODO(mgraczyk): We want to show message loading state while this is
        // happening.
        if (mustCreateSession) {
          await createSession(
            sessionId,
            shorten(newChatMessages[0].content.question.text, 50),
          )
        }

        // TODO(mgraczyk): Allow returning an error for each question here.
        answerResponses = await getAnswers(requests)
        if (answerResponses.length !== requests.length) {
          throw new Error("Wrong number of answers")
        }
        saveAnswerMessages = true
      } catch (error) {
        handleError({ error, prefix: "Couldn't get answer" })
        // TODO(mgraczyk): Probabably only show one error for a bulk request.
        answerResponses = requests.map((request) => ({
          question: request.question,
          answer: null,
          error: getUserFacingError(error),
        }))
        // Do not save error answer to database
        saveAnswerMessages = false
      }

      const nowAfterAnswer = Timestamp.now() as FirebaseFirestore.Timestamp
      const answeredMessages: ChatMessageUIState[] = newChatMessages.map(
        (m, i) => ({
          ...m,
          content: {
            question: answerResponses[i].question,
            answer: answerResponses[i].answer,
            answererRequestId: answerResponses[i].request_id ?? null,
          },
          answerLoading: false,
          error: answerResponses[i].error,
          updated_at: nowAfterAnswer,
        }),
      )

      // TODO(mgraczyk): Create many.
      if (saveAnswerMessages) {
        await Promise.all(
          answeredMessages.map((m) =>
            createChatMessage(
              activeGroupOid,
              authUser.uid,
              m,
              sessionId,
              mustCreateSession || skipUpdateSession,
            ),
          ),
        )
      }

      setSessionMessages((t: Record<string, ChatMessageUIState[]>) => ({
        ...t,
        [sessionId]: replaceMany(
          t[sessionId] ?? [],
          answeredMessages,
          (m) => m.oid,
        ),
      }))
    },
    [
      activeGroupOid,
      authUser.uid,
      createSession,
      selectedSessionId,
      selectedSession,
      handleError,
    ],
  )

  // TODO(mgraczyk): Don't show messages from sessions that are being deleted.
  const messages = sessionMessages[selectedSessionId] ?? EMPTY_ARRAY

  const createNewQuestionMessage = useCallback(
    async (question_text: string) => {
      const getAnswers = async (requests: GetAnswerInput[]) => {
        const getAnswerResponses = await Promise.all(
          requests.map((request) =>
            getAnswer({
              ...request,
              // TODO(mgraczyk): Other chat surfaces?
              analytics_surface:
                ANALYTICS_PRODUCT === "WEB" ? "WEB_CHAT" : "EXTENSION_CHAT",
            }),
          ),
        )

        return requests.map((request, i) => ({
          question: request.question,
          answer: getAnswerResponses[i].answer,
          request_id: getAnswerResponses[i].request.request_id,
        }))
      }

      const requests = [
        {
          question: {
            text: question_text,
            details: null,
            location: null,
            details_location: null,
          },
          previous_messages: getPreviousMessages(messages),
        },
      ]
      await getAnswersWithMessages(requests, getAnswers)
    },
    [getAnswersWithMessages, messages],
  )

  // Show the spinner only if we have no messages.
  // TODO(mgraczyk): We should show a spinner below existing messages while we
  // are loading so that messages don't appear out of nowhere.
  const messagesLoading =
    !(sessionMessagesLoaded[selectedSessionId] ?? false) &&
    messages.length === 0

  const value = useMemo(
    () => ({
      sessions,
      selectedSession,
      selectedSessionId: selectedSession?.oid ?? "",
      setSelectedSessionId,
      selectNewSession,
      deleteSession,
      sessionLoading: sessionsLoading,
      messages,
      messagesLoading: sessionsLoading || messagesLoading,
      getAnswersWithMessages,
      createNewQuestionMessage,
      updateMessage,
      deleteMessage,
      deleteAllMessages,
    }),
    [
      sessions,
      selectedSession,
      setSelectedSessionId,
      selectNewSession,
      deleteSession,
      sessionsLoading,
      messages,
      messagesLoading,
      getAnswersWithMessages,
      createNewQuestionMessage,
      updateMessage,
      deleteMessage,
      deleteAllMessages,
    ],
  )

  return <ChatContext.Provider value={value}>{children}</ChatContext.Provider>
}
