import { pyBackendAxiosInstance } from "../api"
import { httpsCallable } from "../serverUtils"
import type { GetDocumentContentArgs } from "./requestTypes.js"

export const getDocumentUrl = httpsCallable<GetDocumentContentArgs, string>(
  "getDocumentUrl",
)

export const reindexUserDocuments = async (
  documentOids: string[],
  run_synchronously: boolean = false,
): Promise<void> => {
  await pyBackendAxiosInstance.post("/documents/reindex-many", {
    document_oids: documentOids,
    run_synchronously,
  })
}

export const redownloadUserDocuments = async (
  documentOids: string[],
  run_synchronously: boolean = false,
): Promise<void> => {
  await pyBackendAxiosInstance.post("/documents/redownload-many", {
    document_oids: documentOids,
    run_synchronously,
  })
}

export const retryUserDocuments = async (
  documentOids: string[],
  run_synchronously: boolean = false,
): Promise<void> => {
  await pyBackendAxiosInstance.post("/documents/retry-many", {
    document_oids: documentOids,
    run_synchronously,
  })
}
