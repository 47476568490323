import {
  Button,
  Card,
  Collapse,
  Form,
  Input,
  Skeleton,
  Space,
  Tag,
  Tooltip,
  Typography,
} from "antd"
import { CheckCircle2, HelpCircle, Loader, XCircle } from "lucide-react"

import type { PlaybookCriteria } from "../../live/playbook/types"
import type { PlaybookOutput } from "../../live/playbook/types"

const { Panel } = Collapse
const { Title, Text } = Typography
const { TextArea } = Input

interface FormDataType {
  criteria: Record<
    string,
    {
      output: string
    }
  >
  notes: string
}

interface CriteriaStatusProps {
  wasAttempted: boolean
  wasCompleted: boolean
  isLoading?: boolean
}

const CriteriaStatus = ({
  wasAttempted,
  wasCompleted,
  isLoading,
}: CriteriaStatusProps) => {
  if (isLoading) {
    return <Loader className="animate-spin text-blue-500" size={20} />
  }
  if (wasCompleted) {
    return (
      <Tooltip title="Successfully completed">
        <CheckCircle2 className="text-green-500" size={20} />
      </Tooltip>
    )
  }
  if (wasAttempted) {
    return (
      <Tooltip title="Attempted but not completed">
        <HelpCircle className="text-yellow-500" size={20} />
      </Tooltip>
    )
  }
  return (
    <Tooltip title="Not attempted">
      <XCircle className="text-red-500" size={20} />
    </Tooltip>
  )
}

interface PlaybookOutputProps {
  output?: PlaybookOutput | null
  loading?: boolean
}

const EditableOutput = ({ output, loading = false }: PlaybookOutputProps) => {
  const [form] = Form.useForm<FormDataType>()

  if (!output && loading) {
    return (
      <div className="w-full">
        <div className="mb-4">
          <Skeleton.Input className="mb-2" style={{ width: 200 }} active />
          <Skeleton.Input style={{ width: 400 }} active />
        </div>

        <Collapse defaultActiveKey={["0"]}>
          <Panel
            header={
              <div className="flex w-full items-center justify-between">
                <Skeleton.Input style={{ width: 150 }} active />
                <Skeleton.Input style={{ width: 80 }} active />
              </div>
            }
            key="0"
          >
            <Space direction="vertical" className="w-full">
              {[1, 2, 3].map((i) => (
                <Card key={i} className="w-full bg-gray-50" size="small">
                  <div className="flex items-start justify-between">
                    <div className="flex-grow">
                      <div className="mb-2 flex items-center gap-2">
                        <Loader
                          className="animate-spin text-blue-500"
                          size={20}
                        />
                        <Skeleton.Input style={{ width: 200 }} active />
                      </div>
                      <div className="pl-6">
                        <Skeleton active paragraph={{ rows: 2 }} />
                      </div>
                    </div>
                  </div>
                </Card>
              ))}
            </Space>
          </Panel>
        </Collapse>
      </div>
    )
  }

  if (!output?.playbook) {
    return null
  }

  const { playbook } = output
  const isProcessing = !output.done_processing

  const completionPercent = Math.round(
    (Object.values(output.criteria_results).filter((r) => r.was_completed)
      .length /
      Object.values(output.criteria_results).length) *
      100,
  )

  const getSectionCompletion = (sectionCriteria: PlaybookCriteria[]) => {
    const criteriaCount = sectionCriteria.length
    const completedCount = sectionCriteria.filter(
      (criterion) => output.criteria_results[criterion.oid]?.was_completed,
    ).length
    return Math.floor((completedCount / criteriaCount) * 100)
  }

  return (
    <div className="w-full">
      <div className="mb-4 flex items-center justify-between">
        <div>
          <Title className="!mb-0" level={3}>
            {playbook.name}
          </Title>
          <div className="flex items-baseline gap-2">
            <Text>{playbook.description}</Text>
            <Tag color={completionPercent === 100 ? "green" : "blue"}>
              {completionPercent}% complete
            </Tag>
          </div>
        </div>
        {!isProcessing && (
          <Button type="primary" disabled>
            Save Changes
          </Button>
        )}
      </div>

      <Form<FormDataType> form={form} className="mt-4">
        <Collapse
          defaultActiveKey={playbook.sections.map(
            (_, sectionIndex) => sectionIndex,
          )}
        >
          {playbook.sections.map((section, sectionIndex) => {
            const sectionCompletion = getSectionCompletion(
              section.criteria || [],
            )

            const panelHeader = (
              <div className="flex w-full items-center justify-between">
                <span>{section.name}</span>
                <Tag color={sectionCompletion === 100 ? "green" : "blue"}>
                  {sectionCompletion}% complete
                </Tag>
              </div>
            )

            return (
              <Panel header={panelHeader} key={sectionIndex}>
                <Space direction="vertical" className="w-full">
                  {section.criteria?.map((criterion) => {
                    const result = output.criteria_results[criterion.oid]
                    return (
                      <Card
                        key={criterion.oid}
                        className="w-full bg-gray-50"
                        size="small"
                      >
                        <div className="flex items-start justify-between">
                          <div className="flex-grow">
                            <div className="mb-2 flex items-center gap-2">
                              <CriteriaStatus
                                wasAttempted={result.was_attempted}
                                wasCompleted={result.was_completed}
                                isLoading={isProcessing}
                              />
                              <Text strong>{criterion.name}</Text>
                            </div>
                            <div className="pl-6">
                              {isProcessing ? (
                                <TextArea
                                  className="mt-2 bg-white"
                                  autoSize={{ minRows: 2 }}
                                  disabled
                                  value="Analyzing..."
                                />
                              ) : (
                                <Form.Item
                                  name={["criteria", criterion.oid, "output"]}
                                  initialValue={result.model_output}
                                >
                                  <TextArea
                                    className="mt-2 bg-white"
                                    autoSize={{ minRows: 2 }}
                                  />
                                </Form.Item>
                              )}

                              {!isProcessing && result.model_explanation && (
                                <div className="mt-2">
                                  <Text type="secondary" italic>
                                    {result.model_explanation}
                                  </Text>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </Card>
                    )
                  })}
                </Space>
              </Panel>
            )
          })}
        </Collapse>
      </Form>
    </div>
  )
}

export default EditableOutput
