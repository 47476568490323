import Tour from "antd/es/tour"
import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react"
import { useNavigate } from "react-router-dom"

import { sleep } from "../../utils"
import { STEPS } from "./steps"

interface TourContext {
  startTour: () => void
}

const TourContext = createContext<TourContext | undefined>(undefined)

interface TourProviderProps {
  children: React.ReactNode
}

export const TourProvider: React.FC<TourProviderProps> = ({ children }) => {
  const [run, setRun] = useState(false)
  const [stepIndex, setStepIndex] = useState(0)
  const navigate = useNavigate()

  const startTour = useCallback(() => {
    // TODO(mgraczyk): Need to navigate to the start page if we're not and if
    // the first target isn't mounted when the tour starts.
    setStepIndex(0)
    setRun(true)
  }, [])

  const antdSteps = useMemo(() => {
    const makeOnClickNextPrev = (i: number, delta: number) => async () => {
      const nextStepIndex = i + delta
      if (nextStepIndex < 0) {
        return
      }
      const nextStep = STEPS[nextStepIndex]
      if (!nextStep?.navigateTo) {
        setStepIndex(nextStepIndex)
        return
      }
      navigate(nextStep.navigateTo)
      if (nextStep.target) {
        let i = 0
        for (i = 0; i < 20; ++i) {
          if (document.querySelector(nextStep.target) !== null) {
            break
          }
          await sleep(50)
        }
      } else {
        // No way to check, just wait. Only happens going back to home.
        await sleep(100)
      }

      setStepIndex(nextStepIndex)
    }
    return STEPS.map((step, i) => ({
      title: step.title,
      description: step.description,
      target: step.target
        ? () => document.querySelector<HTMLElement>(step.target)!
        : null,
      placement: step.placement,
      nextButtonProps: {
        onClick: makeOnClickNextPrev(i, 1),
      },
      prevButtonProps: {
        children: "Back",
        onClick: makeOnClickNextPrev(i, -1),
      },
    }))
  }, [navigate])

  const value = useMemo(
    () => ({
      startTour,
    }),
    [startTour],
  )

  return (
    <TourContext.Provider value={value}>
      <Tour
        open={run}
        current={stepIndex}
        onClose={() => setRun(false)}
        placement="center"
        type="default"
        mask={{ color: "rgba(0, 0, 0, .4)" }}
        steps={antdSteps}
        disabledInteraction
      />
      {children}
    </TourContext.Provider>
  )
}

export const useTour = () => {
  const context = useContext(TourContext)

  if (!context) {
    throw new Error("useTour must be used within a TourProvider")
  }

  return context
}
