import { ExternalLink } from "lucide-react"
import { useMemo } from "react"

import type { SheetDiscussion } from "../../discussions/types"
import { isVerbatimFromAnswerBank } from "../../hooks/useEnrichedReference"
import { getColumnNumber } from "../../sheets/utils"
import type { AnswerReference } from "../../types/answerer"
import type { StoredGeneratedAnsweredQuestion } from "../../types/jobs"
import type { AnswerQuestionnaireJob } from "../../types/jobs"
import type { WorksheetRange } from "../../types/sheets"
import AnswerFeedbackButtons from "../AnswerFeedbackButtons"
import Discussions from "../Discussions/Discussions"
import ReferenceLink from "../ReferenceLink"
import { useGridContext } from "./GridContext"

const SURFACE = "WEB_QUESTIONNAIRE_ASSISTANT"

interface Props {
  references?: AnswerReference[]
  activeGroupOid: string
  selectedSheet: string
  job: AnswerQuestionnaireJob
  discussions?: SheetDiscussion[]
  answer?: StoredGeneratedAnsweredQuestion
  discussionsError?: Error
  location: WorksheetRange
}

const CellSidebarPane: React.FC<Props> = ({
  references,
  activeGroupOid,
  selectedSheet,
  job,
  discussions,
  answer,
  discussionsError,
  location,
}) => {
  const { cellFocused, onClickLocation } = useGridContext()

  const filteredDiscussions = useMemo(() => {
    if (!discussions?.length || !cellFocused) return
    return discussions.filter((discussion) => {
      const range = discussion.location
      return (
        range.sheetName === selectedSheet &&
        getColumnNumber(cellFocused.colId) == range.firstColIndex &&
        cellFocused.rowIndex + 1 == range.firstRowIndex
      )
    })
  }, [cellFocused, discussions, selectedSheet])

  return (
    <>
      {references?.length ? (
        <div>
          <h3>
            <span className="mr-1 font-bold text-purple-500">
              {`${cellFocused?.colId}${(cellFocused?.rowIndex ?? 0) + 1}`}
            </span>
            References
          </h3>
          {references.map((r, idx) => (
            <ReferenceLink
              index={idx}
              key={`${r.document_oid}:${r.piece_oid}:${r?.knowledge_item_oid}`}
              reference={r}
              loadEagerly
              isVerbatimFromAnswerBank={isVerbatimFromAnswerBank(references)}
            />
          ))}
        </div>
      ) : answer ? (
        <div className="mt-4">No references to display</div>
      ) : null}

      {answer ? (
        <div>
          <div className="mt-2 flex gap-2">
            <AnswerFeedbackButtons
              surface={SURFACE}
              answer={answer}
              answererRequestId={answer.last_quilt_answer_request_id}
            />
          </div>

          <div>
            <h3 className="mb-0 mt-4">Approval</h3>
            <div className="text-sm">
              <span className="font-semibold">Owned By: </span>
              <span className="">{answer.last_assigned_to?.email}</span>
            </div>
            <div className="text-sm">
              {answer.last_reviewed_by ? (
                <>
                  <span className="font-semibold">Approved By: </span>
                  <span className="">{answer.last_reviewed_by?.email}</span>
                </>
              ) : (
                <span>Not yet approved</span>
              )}
            </div>
          </div>

          <div>
            <a
              href={`/questionnaire-assistant/review/feed/${job.oid}?oid=${answer.oid}`}
              target="_blank"
              className="text-primary mt-4 flex items-center gap-2 font-semibold underline"
            >
              <ExternalLink size={16} /> Open in Feed View
            </a>
            <p className="mt-1 text-sm text-gray-400">
              Ownership and approval can be managed in the feed view.
            </p>
          </div>
        </div>
      ) : null}

      <Discussions
        group_oid={activeGroupOid}
        discussions={filteredDiscussions}
        job_id={job.oid}
        location={location}
        kind="SHEET"
        discussionsError={discussionsError}
        onClickLocation={onClickLocation}
      />
    </>
  )
}

export default CellSidebarPane
