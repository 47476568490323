import {
  Timestamp,
  deleteDoc,
  doc,
  setDoc,
  updateDoc,
} from "firebase/firestore"

import {
  CHAT_SESSIONS_SUBCOLLECTION,
  type ChatMessage,
  type ChatSession,
  MESSAGES_SUBCOLLECTION,
} from "../../chat/types"
import { db } from "../../firebaseApp"
import {
  GROUPS_COLLECTION,
  GROUP_MEMBERSHIP_SUBCOLLECTION,
} from "../../types/common"
import type { OmitOverUnion } from "../../types/typeHelpers"
import type { ChatMessageUIState } from "./types"

export const createNewChatSession = async <T extends ChatSession>(
  groupOid: string,
  uid: string,
  oid: string,
  creationRequest: OmitOverUnion<
    T,
    "oid" | "created_at" | "last_message_time" | "deleted"
  >,
): Promise<T> => {
  const now = Timestamp.now() as FirebaseFirestore.Timestamp

  // TODO(mgraczyk): Fix cast here.
  const sessionToWrite = {
    ...creationRequest,
    created_at: now,
    last_message_time: now,
    deleted: false,
  } as T

  await setDoc(
    doc(
      db,
      GROUPS_COLLECTION,
      groupOid,
      GROUP_MEMBERSHIP_SUBCOLLECTION,
      uid,
      CHAT_SESSIONS_SUBCOLLECTION,
      oid,
    ),
    sessionToWrite,
  )
  return { ...sessionToWrite, oid }
}

// Creates a chat message in the given session.
export const createChatMessage = async (
  groupOid: string,
  uid: string,
  message: ChatMessage,
  sessionId: string,
  skipUpdateSession = false,
): Promise<ChatMessage> => {
  // Discard any accidentally included extra fields.
  const messageToWrite: Omit<ChatMessage, "oid"> = {
    content: message.content,
    timestamp: message.timestamp,
    updated_at: message.updated_at,
  }

  await setDoc(
    doc(
      db,
      GROUPS_COLLECTION,
      groupOid,
      GROUP_MEMBERSHIP_SUBCOLLECTION,
      uid,
      CHAT_SESSIONS_SUBCOLLECTION,
      sessionId,
      MESSAGES_SUBCOLLECTION,
      message.oid,
    ),
    messageToWrite,
  )

  if (!skipUpdateSession) {
    const sessionUpdates: Partial<ChatSession> = {
      last_message_time: message.updated_at,
    }

    await updateDoc(
      doc(
        db,
        GROUPS_COLLECTION,
        groupOid,
        GROUP_MEMBERSHIP_SUBCOLLECTION,
        uid,
        CHAT_SESSIONS_SUBCOLLECTION,
        sessionId,
      ),
      sessionUpdates,
    )
  }

  return message
}

export const deleteSession = async (
  groupOid: string,
  uid: string,
  sessionId: string,
) => {
  // Mark the session as deleted. We will do the actual deletion later in the
  // background.
  await updateDoc(
    doc(
      db,
      GROUPS_COLLECTION,
      groupOid,
      GROUP_MEMBERSHIP_SUBCOLLECTION,
      uid,
      CHAT_SESSIONS_SUBCOLLECTION,
      sessionId,
    ),
    { deleted: true },
  )
}

export const deleteChatMessage = async (args: {
  groupOid: string
  uid: string
  sessionOid: string
  messageOid: string
}): Promise<void> => {
  if (!args.sessionOid) {
    return
  }

  await deleteDoc(
    doc(
      db,
      GROUPS_COLLECTION,
      args.groupOid,
      GROUP_MEMBERSHIP_SUBCOLLECTION,
      args.uid,
      CHAT_SESSIONS_SUBCOLLECTION,
      args.sessionOid,
      MESSAGES_SUBCOLLECTION,
      args.messageOid,
    ),
  )
}

export const mergeMessages = (
  messages: ChatMessageUIState[],
): ChatMessageUIState[] => {
  const result: Record<string, ChatMessageUIState> = {}
  for (const message of messages) {
    if (!result[message.oid]) {
      result[message.oid] = message
    } else if (
      // TODO(mgraczyk): Backfill updated_at in db so we can skip this.
      message.updated_at &&
      result[message.oid].updated_at &&
      message.updated_at._compareTo(result[message.oid].updated_at) > 0
    ) {
      result[message.oid] = message
    } else if (result[message.oid].answerLoading && !message.answerLoading) {
      result[message.oid] = message
    }
  }

  return Object.values(result)
}
