import type { AxiosResponse } from "axios"

import { functionsAxiosInstance } from "./api"

interface Options {
  method?: "GET" | "POST"
  authNotRequired?: boolean
  withCredentials?: boolean
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-unnecessary-type-parameters
function httpsCallable<TArgs extends never, TResult = object>(
  name: string,
  options?: Options,
): () => Promise<AxiosResponse<TResult>>

// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-parameters
function httpsCallable<TArgs extends object, TResult = object>(
  name: string,
  options?: Options,
): (data: TArgs) => Promise<AxiosResponse<TResult>>

// TODO(mgraczyk): Rename to tsBackendFunction.
function httpsCallable<TArgs extends object, TResult = object>(
  name: string,
  options?: Options,
): (data: TArgs) => Promise<AxiosResponse<TResult>> {
  // Reroute to cloud run backend.
  return async (data: TArgs): Promise<AxiosResponse<TResult>> => {
    return await functionsAxiosInstance.request<TResult>({
      url: `v1/${name}`,
      data,
      method: options?.method ?? "POST",
      metadata: { authNotRequired: options?.authNotRequired ?? false },
      withCredentials: options?.withCredentials ?? false,
    })
  }
}

export { httpsCallable }
