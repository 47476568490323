import { useMemo } from "react"
import { type Client } from "typesense"

import { EMPTY_ARRAY } from "../constants"
import { useTypesenseSearch } from "../search/useTypesenseSearch"
import type { AnswerReferenceKind } from "../types/answerer"
import type { ReferenceSearchHit } from "./types"

type SearchFunction = (q: string) => Promise<ReferenceSearchHit[]>

const getSearchFunction = (
  client: Client | undefined,
  collectionName: string | undefined,
  docType: AnswerReferenceKind | "ALL",
): SearchFunction => {
  if (!client || !collectionName) {
    // eslint-disable-next-line @typescript-eslint/require-await
    return async () => EMPTY_ARRAY as ReferenceSearchHit[]
  }
  const baseFields = "id,prompt,document_title,ref_oid"

  let filter_by: string | undefined
  let include_fields: string
  let query_by: string
  let query_by_weights: string
  if (docType === "KNOWLEDGE_ITEM") {
    filter_by = "is_knowledge_item:=true"
    include_fields = baseFields + ",knowledge_item_oid"
    query_by = "prompt"
    query_by_weights = "1"
  } else if (docType === "SOURCE_DOCUMENT_PIECE") {
    filter_by = "is_source_document_piece:=true"
    include_fields = baseFields + ",source_document_oid"
    query_by = "document_title,prompt"
    query_by_weights = "100,1"
  } else {
    filter_by = undefined
    include_fields = baseFields + ",knowledge_item_oid,source_document_oid"
    query_by = "document_title,prompt"
    query_by_weights = "100,1"
  }

  return async (q) => {
    const response = await client
      .collections(collectionName)
      .documents()
      .search({
        q,
        query_by,
        query_by_weights,
        prioritize_token_position: true,
        filter_by,
        include_fields,
        enable_highlight_v1: false,
        limit: 50,
      })

    return (response.hits ?? EMPTY_ARRAY) as unknown[] as ReferenceSearchHit[]
  }
}

interface Result {
  loading: boolean
  error?: Error
  searchFunction: SearchFunction
}

const useReferencesSearch = (docType: AnswerReferenceKind | "ALL"): Result => {
  const { loading, error, client, collectionName } = useTypesenseSearch()

  const searchFunction = useMemo(
    () => getSearchFunction(client, collectionName, docType),
    [client, collectionName, docType],
  )

  return {
    loading,
    error,
    searchFunction,
  }
}

export default useReferencesSearch
