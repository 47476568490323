import type {
  AnswerResponseKind,
  AnswererControls,
} from "./answerResponseType.js"
import type { MimeType } from "./mimetype.js"

// TODO(mgraczyk): Move sheets types to sheets subdirectory.
export interface CellRangeCoordinates {
  firstColIndex: number
  lastColIndex: number
  firstRowIndex: number
  lastRowIndex: number
}

export type CellRange = CellRangeCoordinates

export interface WorksheetContext {
  sheetName: string | null | undefined
  sheetId: number | null | undefined
}

export type WorksheetRange = CellRange & WorksheetContext

export interface SheetsRange extends CellRangeCoordinates {
  spreadsheetId: string
  sheetName: string | null
  sheetId: number | null
  firstColIndex: number
  lastColIndex: number
  firstRowIndex: number
  lastRowIndex: number
}

export interface CellsSelectedData extends SheetsRange {
  url: string
  spreadsheetTitle: string
  mimetype: MimeType
}

export enum ResponseStyle {
  DEFAULT = "DEFAULT",
  VERY_SHORT = "VERY_SHORT",
}

export const responseStyles = [ResponseStyle.DEFAULT, ResponseStyle.VERY_SHORT]

export interface QuestionAnswerLayout {
  // Whether the questions and answers are in a row or column.
  // Default is column.
  is_row: boolean
  // 1-based index of the row or column containing questions.
  primary_question_index: number
  // 1-based index of the primary row or column containing answers.
  primary_answer_index: number
  answer_kind: AnswerResponseKind
  // If the question has multiple parts, this is the second part.
  secondary_question_index?: number | null
  // If the answer is categorical, these are the allowed values.
  answer_categorical_options?: string[] | null
  // If the answer has multiple parts, this is the second part.
  secondary_answer_index?: number | null
  // Location of the question identifier, if there is one.
  question_id_index?: number | null
  // 1-based index of the header, if we know where it is.
  // If the spreadsheet is colwise (is_row=false), this is the row index.
  header_index?: number | null
  // 1-based indexes of hidden columns
  hidden_columns?: number[] | null
  // How the LLM should respond in free text answers.
  response_style?: ResponseStyle
}

export interface QuestionAnswerLayoutForAnswering extends QuestionAnswerLayout {
  require_verbatim_kind?: "ANSWER_BANK" | null
  answerer_controls?: AnswererControls | null
}
