import type { Dispatch } from "react"
import { createContext } from "react"

import { VOID_FUNCTION } from "../../constants"
import type { Action } from "./reducer"
import type { State } from "./state"
import { initialState } from "./state"

const WizardContext = createContext<{
  state: State
  dispatch: Dispatch<Action>
}>({
  state: initialState,
  dispatch: VOID_FUNCTION,
})

export default WizardContext
