import { useCallback, useState } from "react"

import { sleep } from "../utils"

// Returns [recentlyCopied, copy, error]
function useCopyToClipboard(): [
  boolean,
  (text: string) => Promise<void>,
  Error | undefined,
]

function useCopyToClipboard(
  defaultText: string,
): [boolean, () => Promise<void>, Error | undefined]

function useCopyToClipboard(
  defaultText?: string,
): [boolean, (text?: string) => Promise<void>, Error | undefined] {
  const [copied, setCopied] = useState<boolean>(false)
  const [error, setError] = useState<Error>()
  const copy = useCallback(
    async (text?: string) => {
      try {
        await navigator.clipboard.writeText((defaultText ?? text)!)
        setCopied(true)
        await sleep(3000)
      } catch (error) {
        console.error("Failed to copy text: ", error)
        setError(error as Error)
      } finally {
        setCopied(false)
      }
    },
    [defaultText],
  )

  return [copied, copy, error]
}

export default useCopyToClipboard
