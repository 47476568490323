import { useCallback, useEffect, useState } from "react"

import { functionsAxiosInstance } from "../api"
import { useActiveUserAuthorizationFromContext } from "../contexts/ActiveUserAuthorizationContext"
import useErrorPopup from "../hooks/useErrorPopup"
import { disconnectSlackBot, getSlackInstallationApi } from "../slack/api"
import type { SlackInstallationMeta } from "../slack/apiTypes"
import { openUrlInPopup } from "../windowUtils"

interface Result {
  slackLoading: boolean
  slackInstallation?: SlackInstallationMeta
  connectSlack: () => Promise<void>
  fetchSlackInstallation: () => Promise<void>
  disconnectSlack: () => Promise<unknown>
}

const useSlackInstallation = (): Result => {
  const { handleError } = useErrorPopup()

  const { authUser, activeGroupOid } = useActiveUserAuthorizationFromContext()

  const [slackLoading, setSlackLoading] = useState(true)
  const [slackInstallation, setSlackInstallation] =
    useState<SlackInstallationMeta>()

  const fetchSlackInstallation = useCallback(async () => {
    setSlackLoading(true)
    try {
      const resp = await getSlackInstallationApi({
        groupOid: activeGroupOid,
      })
      setSlackInstallation(resp.data)
    } catch (error) {
      handleError({ prefix: "Unable to fetch Slack integration", error })
    } finally {
      setSlackLoading(false)
    }
  }, [activeGroupOid, handleError])

  const disconnectSlack = useCallback(async () => {
    setSlackLoading(true)
    try {
      await disconnectSlackBot()
      setSlackInstallation(undefined)
    } catch (error) {
      handleError({ prefix: "Unable to fully disconnect Slack Bot", error })
      // Fetch again in case it was removed.
      await fetchSlackInstallation()
    } finally {
      setSlackLoading(false)
    }
  }, [handleError, fetchSlackInstallation])

  useEffect(() => {
    void fetchSlackInstallation()
  }, [fetchSlackInstallation])

  const connectSlack = useCallback(async () => {
    try {
      const token = await authUser.getIdToken()
      const url = `${functionsAxiosInstance.defaults.baseURL}/slackApp/slack/install?token=${token}`
      await openUrlInPopup(url, "Slack OAuth")
      await fetchSlackInstallation()
    } catch (e) {
      handleError({ prefix: "Unable to add Quilt to Slack", error: e })
    } finally {
      setSlackLoading(false)
    }
  }, [handleError, authUser, fetchSlackInstallation])

  return {
    slackLoading,
    slackInstallation,
    connectSlack,
    fetchSlackInstallation,
    disconnectSlack,
  }
}

export default useSlackInstallation
