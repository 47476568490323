import { httpsCallable } from "../serverUtils"
import type {
  DeleteUserDocumentsRequest,
  KnowledgeItem,
  KnowledgeItemAssignManyRequest,
  KnowledgeItemCreationRequest,
  KnowledgeItemEditManyRequest,
  KnowledgeItemReviewManyRequest,
  KnowledgeItemUnassignManyRequest,
} from "./types"

export const createKnowledgeItemApi = httpsCallable<
  KnowledgeItemCreationRequest,
  KnowledgeItem
>("createKnowledgeItem")

export const editKnowledgeItemsApi =
  httpsCallable<KnowledgeItemEditManyRequest>("editKnowledgeItems")

export const deleteUserDocumentsApi = httpsCallable<DeleteUserDocumentsRequest>(
  "deleteUserDocuments",
)

export const reviewKnowledgeItems =
  httpsCallable<KnowledgeItemReviewManyRequest>("reviewKnowledgeItems")

export const assignKnowledgeItems =
  httpsCallable<KnowledgeItemAssignManyRequest>("assignKnowledgeItems")

export const unassignKnowledgeItems =
  httpsCallable<KnowledgeItemUnassignManyRequest>("unassignKnowledgeItems")
