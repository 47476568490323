import { ref as storageRef, uploadBytes } from "firebase/storage"

import type { FileData } from "../documents/types"
import { auth, firebaseStorage } from "../firebaseApp"
import { randomId } from "../utils"

const uploadUserFile = async (
  targetFolder: "source_document_uploads" | "answer_questionnaire_job_uploads",
  fileData: FileData,
) => {
  const user = auth.currentUser
  if (!user) {
    throw new Error("Not logged in")
  }

  // Generate a random upload id.
  const uploadId = randomId()
  const bucketPath = `/${targetFolder}/${user.uid}/${uploadId}`
  const objectRef = storageRef(firebaseStorage, bucketPath)

  // TODO(mgraczyk): Use hook in parent to monitor upload progress.
  const metadata = {
    contentType: fileData.fileType,
    contentDisposition: `inline; filename="${fileData.fileName ?? uploadId}"`,
  }

  const uploadRes = await uploadBytes(
    objectRef,
    fileData.fileContents,
    metadata,
  )
  console.debug("Upload result", uploadRes)
  return { id: uploadId, uri: objectRef.toString() }
}

export default uploadUserFile
