import EchoCancellerProcessor from "./worklets/EchoCancellerProcessor?worker&url"

export async function getEchoCancellerNode(
  context: AudioContext,
): Promise<AudioWorkletNode> {
  try {
    await context.audioWorklet.addModule(EchoCancellerProcessor)
    return new AudioWorkletNode(context, "echo-canceller-processor", {
      numberOfInputs: 2,
      numberOfOutputs: 1,
      outputChannelCount: [1],
    })
  } catch (e) {
    console.error(e)
    throw new Error(
      `Could not add audioWorklet module: ${EchoCancellerProcessor}`,
    )
  }
}
