import { httpsCallable } from "../serverUtils"
import type {
  GetSlackChannelsRequest,
  GetSlackChannelsResponse,
  GetSlackUsersRequest,
  GetSlackUsersResponse,
  SlackConfiguration,
  SlackInstallationMeta,
  SlackTeamUserAssociationRequest,
} from "./apiTypes"

export const associateSlackIdWithUserUid =
  httpsCallable<SlackTeamUserAssociationRequest>("associateSlackIdWithUserUid")

export const getSlackInstallationApi = httpsCallable<
  { groupOid: string },
  SlackInstallationMeta | undefined
>("getSlackInstallation")

export const updateSlackConfiguration = httpsCallable<{
  newConfig: SlackConfiguration
}>("updateSlackConfiguration")

export const getSlackChannelsApi = httpsCallable<
  GetSlackChannelsRequest,
  GetSlackChannelsResponse
>("getSlackChannels")

export const getSlackWorkspaceMembersApi = httpsCallable<
  GetSlackUsersRequest,
  GetSlackUsersResponse
>("getSlackWorkspaceMembers")

export const disconnectSlackBot = httpsCallable("disconnectSlackBot")
