import { httpsCallable } from "../serverUtils"
import type {
  NotificationPreferences,
  QuiltAdminAdhocNotificationCreationRequest,
} from "./types"

export const quiltAdminCreateAdHocNotification =
  httpsCallable<QuiltAdminAdhocNotificationCreationRequest>(
    "quiltAdmin/createAdhocNotification",
  )

export const updateNotificationPreferences =
  httpsCallable<NotificationPreferences>("updateNotificationPreferences")
