import Button from "antd/es/button"
import Checkbox from "antd/es/checkbox"
import Mentions from "antd/es/mentions"
import Popover from "antd/es/popover"
import Tooltip from "antd/es/tooltip"
import { MessageSquareIcon } from "lucide-react"
import { useCallback, useState } from "react"

import { useActiveUserAuthorizationFromContext } from "../contexts/ActiveUserAuthorizationContext"
import { createComments } from "../discussions/api"
import useErrorPopup from "../hooks/useErrorPopup"
import { useGroupMembers } from "../hooks/useGroupMembers"
import type { WorksheetRange } from "../types/sheets"

interface BulkCommentFormProps {
  jobOid: string
  answerLocations: (WorksheetRange | null | undefined)[]
  onClose: () => void
}

const BulkCommentForm: React.FC<BulkCommentFormProps> = ({
  jobOid,
  answerLocations,
  onClose,
}) => {
  const [comment, setComment] = useState<string>("")
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isMentionedAssigned, setIsMentionedAssigned] = useState(true)
  const { handleError, handleSuccess } = useErrorPopup()
  const { activeGroupOid } = useActiveUserAuthorizationFromContext()
  const [members, loading] = useGroupMembers(activeGroupOid)

  const mentionedMember = members?.find((member) =>
    Mentions.getMentions(comment)
      .map((m) => m.value)
      .includes(member.email),
  )

  const handleSubmit = async () => {
    if (!comment.trim()) {
      handleError({ error: "Please enter a comment" })
      return
    }
    const assignedMember = isMentionedAssigned ? mentionedMember : undefined
    const createCommentArgs = {
      requests: answerLocations
        .filter((location): location is WorksheetRange => location != null)
        .map((location) => ({
          group_oid: activeGroupOid,
          text: comment,
          assignment: assignedMember
            ? {
                uid: assignedMember.uid,
                email: assignedMember.email,
              }
            : undefined,
          kind: "SHEET" as const,
          location,
          job_id: jobOid,
        })),
    }

    setIsSubmitting(true)
    try {
      await createComments(createCommentArgs)
      handleSuccess("Comments added successfully")
      setComment("")
      onClose()
    } catch (error) {
      handleError({ error, prefix: "Failed to add comments" })
    } finally {
      setIsSubmitting(false)
    }
  }

  return (
    <div className="w-[400px]">
      <Mentions
        autoSize
        allowClear
        placeholder="Use @ to mention team members by email..."
        options={members?.map((member) => ({
          value: member.email,
          label: member.email,
        }))}
        value={comment}
        onChange={setComment}
        rows={4}
        loading={loading}
      />
      <div className="mt-4 flex items-center justify-between">
        <div>
          <Button onClick={onClose} className="mr-2">
            Cancel
          </Button>
          <Button
            type="primary"
            onClick={handleSubmit}
            loading={isSubmitting}
            disabled={!comment.trim() || isSubmitting}
          >
            Add Comment
          </Button>
        </div>
      </div>
      {mentionedMember && (
        <Checkbox
          key={mentionedMember.uid}
          checked={isMentionedAssigned}
          onChange={(e) => {
            setIsMentionedAssigned(e.target.checked)
          }}
          className="mt-4 text-xs"
        >
          Assign to {mentionedMember.email}
        </Checkbox>
      )}
    </div>
  )
}

interface BulkCommentButtonProps {
  jobOid: string
  answerLocations: (WorksheetRange | null | undefined)[]
  small?: boolean
}

const BulkCommentButton: React.FC<BulkCommentButtonProps> = ({
  jobOid,
  answerLocations,
  small,
}) => {
  const [open, setOpen] = useState(false)
  const hide = useCallback(() => {
    setOpen(false)
  }, [setOpen])

  return (
    <Popover
      content={
        <BulkCommentForm
          jobOid={jobOid}
          answerLocations={answerLocations}
          onClose={hide}
        />
      }
      trigger="click"
      placement="bottomLeft"
      open={open}
      onOpenChange={setOpen}
    >
      <Tooltip title="Add comments and assign team members to work on the selected questions">
        <Button
          icon={<MessageSquareIcon />}
          className="flex items-center justify-center"
          size={small ? "small" : "middle"}
          disabled={answerLocations.length === 0}
        >
          Discuss
        </Button>
      </Tooltip>
    </Popover>
  )
}
export default BulkCommentButton
