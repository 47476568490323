import { Button, Popover, Radio, type RadioChangeEvent } from "antd"
import { ArrowDownUpIcon } from "lucide-react"
import { useCallback } from "react"

export type SortByKind = "priority" | "confidence" | null

const SORT_BY_OPTIONS = [
  { label: "Default", value: "default" },
  { label: "Priority", value: "priority" },
  { label: "Confidence", value: "confidence" },
]

interface Props {
  sortByKind: SortByKind
  setSortByKind: (kind: SortByKind) => void
}

const SortByButton: React.FC<Props> = ({ sortByKind, setSortByKind }) => {
  const onChange = useCallback(
    (e: RadioChangeEvent) => {
      const value = e.target.value as SortByKind | "default"
      setSortByKind(value === "default" ? null : value)
    },
    [setSortByKind],
  )

  const content = (
    <Radio.Group
      onChange={onChange}
      value={sortByKind ?? "default"}
      options={SORT_BY_OPTIONS}
      className="flex flex-col gap-2 p-1"
    />
  )

  return (
    <Popover
      content={content}
      title="Sort By"
      trigger="click"
      placement="bottomLeft"
    >
      <Button
        icon={<ArrowDownUpIcon size="1em" />}
        type={sortByKind ? "primary" : "default"}
      >
        {SORT_BY_OPTIONS.find((opt) => opt.value === (sortByKind ?? "default"))
          ?.label ?? "Default"}
      </Button>
    </Popover>
  )
}

export default SortByButton
