/* eslint @typescript-eslint/no-deprecated: "off" */
import { Timestamp } from "@firebase/firestore"
import { useCallback, useEffect, useState } from "react"
import { useUnmount } from "usehooks-ts"

import { sendAnalyticsEvent } from "../../analytics"
import type { LiveUIEvent } from "../../live/types"
import type { LiveSessionConfig } from "../../live/types"
import type { AudioDataEvent } from "../../live/types"
import { randomId } from "../../utils"
import { getEchoCancellerNode } from "./echoCanceller"
import useLiveWebSocket from "./useWebsocket"

const SAMPLE_RATE = 16_000

type ErrorCallbackType = (error: Error, message: string) => void
type NodeId =
  | "SYSTEM" // System audio only - both microphone and system are available
  | "USER" // User audio only (echo cancelled) - both microphone and system are available
  | "UNKNOWN" // User audio, maybe mixed with system audio - microphone only is available

function createAudioEventProcessor(
  context: AudioContext,
  source: AudioNode,
  onAudioData: (event: AudioDataEvent) => void,
  nodeId: NodeId,
  displayName: string,
  isMic: boolean,
  callStartTime: Date,
): ScriptProcessorNode {
  const processor = context.createScriptProcessor(512, 1, 1)
  processor.addEventListener(
    "audioprocess",
    (audioProcessingEvent: AudioProcessingEvent) => {
      const inputBuffer = audioProcessingEvent.inputBuffer
      if (inputBuffer.numberOfChannels !== 1) {
        throw new Error(
          "Expected 1 channel, but got " +
            inputBuffer.numberOfChannels.toString(),
        )
      }

      const channel = 0
      const inputData = inputBuffer.getChannelData(channel)
      const s16Data = inputData.map((x) => Math.round(x * 32767)).toString()

      const audioTimestamp = context.getOutputTimestamp()
      const timestamp_ms =
        audioTimestamp.contextTime === undefined
          ? 0
          : audioTimestamp.contextTime * 1000.0

      const speech_time = new Date(callStartTime)
      speech_time.setMilliseconds(speech_time.getMilliseconds() + timestamp_ms)

      const event: AudioDataEvent = {
        oid: randomId(),
        kind: "AUDIO_DATA",
        sample_rate: inputBuffer.sampleRate,
        channels: 1,
        node_id: nodeId,
        speaker_display_name: displayName,
        timestamp_ms: Math.round(timestamp_ms),
        speech_time,
        created_at: Timestamp.fromDate(
          new Date(),
        ) as FirebaseFirestore.Timestamp,
        is_mic: isMic,
        packed_data: s16Data,
      }
      onAudioData(event)
    },
  )
  source.connect(processor)
  const gainNode = new GainNode(context, { gain: 0 })
  processor.connect(gainNode)
  gainNode.connect(context.destination)
  return processor
}

async function requestSystemAudioStream(): Promise<MediaStream> {
  console.log("Requesting display media for system audio")
  const systemStream = await navigator.mediaDevices.getDisplayMedia({
    audio: {
      echoCancellation: true,
      noiseSuppression: true,
      channelCount: 1,
      sampleRate: SAMPLE_RATE,
    },
    // @ts-expect-error types are wrong
    systemAudio: "include",
    selfBrowserSurface: "exclude",
    monitorTypeSurfaces: "include",
    surfaceSwitching: "exclude",
    preferCurrentTab: false,

    // Explicitly request video to ensure that the system audio is included
    video: true,
  })

  // Remove video tracks from the system stream
  systemStream.getVideoTracks().forEach((track) => {
    track.stop()
    systemStream.removeTrack(track)
  })

  systemStream.getAudioTracks().forEach((track) => {
    track.onended = () => {
      // This event is fired when the permission changes or when source permanently stops sending
      // data on the stream. It is NOT fired when calling track.stop()

      // TODO(arie): We should call the stop method of the react component now
      console.error("System track ended unexpectedly", track)
    }
  })

  if (systemStream.getAudioTracks().length === 0) {
    throw new Error(
      "No audio was shared, disable in audio settings to use Microphone only",
    )
  }

  return systemStream
}

async function requestUserAudioStream(): Promise<MediaStream> {
  console.log("Requesting display media for user audio")
  const userStream = await navigator.mediaDevices.getUserMedia({
    audio: {
      echoCancellation: true,
      noiseSuppression: true,
      channelCount: 1,
      sampleRate: SAMPLE_RATE,
    },
    video: false,
  })

  if (userStream.getAudioTracks().length === 0) {
    throw new Error(
      "No audio was shared, enable Microphone in your browser permissions",
    )
  }

  userStream.getAudioTracks().forEach((track) => {
    track.onended = () => {
      // This event is fired when the permission changes or when source permanently stops sending
      // data on the stream. It is NOT fired when calling track.stop()
      console.error("User track ended", track)
      // TODO(arie): We should call the stop method of the react component now
    }
  })

  return userStream
}

function createAudioContext(): AudioContext {
  const context = new AudioContext({
    latencyHint: "interactive",
    sampleRate: SAMPLE_RATE,
  })
  console.log("Created audio context", {
    outputLatency: context.outputLatency,
    baseLatency: context.baseLatency,
    sampleRate: context.sampleRate,
    state: context.state,
  })
  return context
}

function stopAudioTracks(stream: MediaStream): void {
  stream.getTracks().forEach((track) => {
    track.enabled = false
    track.stop()
  })
}

async function cleanupResources(
  context: AudioContext,
  userStream?: MediaStream,
  systemStream?: MediaStream,
): Promise<void> {
  if (userStream) {
    console.log("Stopping user tracks")
    stopAudioTracks(userStream)
  }

  if (systemStream) {
    console.log("Stopping system tracks")
    stopAudioTracks(systemStream)
  }

  console.log("Closing context")
  await context.close()
}

class AudioInputProcessor {
  callOid: string
  context: AudioContext
  userStream?: MediaStream
  systemStream?: MediaStream
  callStartTime: Date

  private constructor(
    callOid: string,
    context: AudioContext,
    callStartTime: Date,
    userStream: MediaStream | undefined,
    systemStream: MediaStream | undefined,
  ) {
    this.callOid = callOid
    this.context = context
    this.userStream = userStream
    this.systemStream = systemStream
    this.callStartTime = callStartTime
  }

  static async create(
    callOid: string,
    includeUserStream: boolean,
    includeSystemStream: boolean,
    onAudioData: (event: AudioDataEvent) => void,
  ): Promise<AudioInputProcessor> {
    const context = createAudioContext()

    let systemStream: MediaStream | undefined
    let userStream: MediaStream | undefined
    const callStartTime = new Date()

    try {
      let systemSource: AudioNode | undefined
      let userSource: AudioNode | undefined
      if (includeSystemStream) {
        systemStream = await requestSystemAudioStream()
        systemSource = context.createMediaStreamSource(systemStream)
      }
      if (includeUserStream) {
        userStream = await requestUserAudioStream()
        userSource = context.createMediaStreamSource(userStream)
      }

      const isMac = navigator.appVersion.includes("Mac")
      if (
        userSource &&
        systemSource &&
        systemStream &&
        isMac &&
        systemStream
          .getAudioTracks()
          .some((track) => track.label.toLowerCase().includes("system audio"))
      ) {
        const echoCanceller = await getEchoCancellerNode(context)
        userSource.connect(echoCanceller, 0, 0)
        systemSource.connect(echoCanceller, 0, 1)
        userSource = echoCanceller
      }

      if (systemSource) {
        console.log("Connecting system stream to audio context")
        createAudioEventProcessor(
          context,
          systemSource,
          onAudioData,
          "SYSTEM",
          "System Audio",
          false,
          callStartTime,
        )
      }

      if (userSource) {
        console.log("Connecting user stream to audio context")
        createAudioEventProcessor(
          context,
          userSource,
          onAudioData,
          systemSource ? "USER" : "UNKNOWN",
          "User Audio",
          true,
          callStartTime,
        )
      }

      return new AudioInputProcessor(
        callOid,
        context,
        callStartTime,
        userStream,
        systemStream,
      )
    } catch (err) {
      // Show info on permission denied.
      console.error("Error when creating transcript processor", err)
      await cleanupResources(context, userStream, systemStream)
      throw err
    }
  }

  async stop(): Promise<void> {
    await cleanupResources(this.context, this.userStream, this.systemStream)
  }

  pause(): void {
    // TODO(arie): add the ability to pause/resume only one of the streams
    if (!this.userStream && !this.systemStream) {
      throw new Error("streams not set")
    }

    this.systemStream?.getAudioTracks().forEach((track) => {
      // TODO(mgraczyk): Send no events instead of sending silence.
      console.log("pausing system audio track", track)
      track.enabled = false
    })
    this.userStream?.getAudioTracks().forEach((track) => {
      console.log("pausing user audio track", track)
      track.enabled = false
    })
  }

  unpause(): void {
    if (!this.userStream && !this.systemStream) {
      throw new Error("streams not set")
    }

    this.systemStream?.getAudioTracks().forEach((track) => {
      console.log("resuming system audio track", track)
      track.enabled = true
    })
    this.userStream?.getAudioTracks().forEach((track) => {
      console.log("resuming user audio track", track)
      track.enabled = true
    })
  }
}

export type PlayState =
  | "starting"
  | "stopped"
  | "playing"
  | "paused"
  | "stopping"

export interface UseTranscriptProcessorResult {
  playState: PlayState
  start: () => Promise<void>
  pause: () => void
  stop: () => Promise<void>
}

const useTranscriptProcessor = (
  callOid: string,
  onEvent: (event: LiveUIEvent) => void,
  includeUserStream: boolean,
  includeSystemStream: boolean,
  sessionConfig: LiveSessionConfig,
  onError: ErrorCallbackType,
): UseTranscriptProcessorResult => {
  const [audioInputProcessor, setAudioInputProcessor] =
    useState<AudioInputProcessor>()
  const [playState, setPlayState] = useState<PlayState>("stopped")
  const { sendOverWebSocket, webSocketState } = useLiveWebSocket(
    callOid,
    onEvent,
    onError,
    playState,
    sessionConfig,
  )
  useUnmount(async () => {
    if (audioInputProcessor) {
      await audioInputProcessor.stop()
    }
  })

  const start = useCallback(async () => {
    if (playState === "starting" || playState === "stopping") {
      return
    } else if (playState === "paused") {
      sendAnalyticsEvent({
        surface: "WEB_LIVE_ASSISTANT",
        event_type: "UNMUTE_LIVE_ASSISTED_CALL",
        event_data: {
          call_oid: callOid,
        },
      })
      audioInputProcessor?.unpause()
      setPlayState("playing")
    } else if (playState === "stopped") {
      sendAnalyticsEvent({
        surface: "WEB_LIVE_ASSISTANT",
        event_type: "START_LIVE_ASSISTED_CALL",
        event_data: {
          call_oid: callOid,
        },
      })
      setPlayState("starting")
      if (includeSystemStream || includeUserStream) {
        try {
          const newAudioInputProcessor = await AudioInputProcessor.create(
            callOid,
            includeUserStream,
            includeSystemStream,
            sendOverWebSocket,
          )
          setAudioInputProcessor(newAudioInputProcessor)
        } catch (err) {
          setPlayState("stopped")
          throw err
        }
      }
    }
  }, [
    playState,
    audioInputProcessor,
    callOid,
    includeUserStream,
    includeSystemStream,
    sendOverWebSocket,
  ])

  useEffect(() => {
    if (playState === "starting" && webSocketState === "open") {
      setPlayState("playing")
    }
  }, [playState, webSocketState])

  const pause = useCallback(() => {
    if (playState !== "playing") {
      return
    }

    sendAnalyticsEvent({
      surface: "WEB_LIVE_ASSISTANT",
      event_type: "MUTE_LIVE_ASSISTED_CALL",
      event_data: {
        call_oid: callOid,
      },
    })

    audioInputProcessor?.pause()
    setPlayState("paused")
  }, [playState, callOid, audioInputProcessor])

  const stop = useCallback(async () => {
    if (playState === "stopped" || playState === "stopping") {
      return
    }

    sendAnalyticsEvent({
      surface: "WEB_LIVE_ASSISTANT",
      event_type: "STOP_LIVE_ASSISTED_CALL",
      event_data: {
        call_oid: callOid,
      },
    })

    setPlayState("stopping")
    await audioInputProcessor?.stop()
    setAudioInputProcessor(undefined)
    setPlayState("stopped")
  }, [playState, callOid, audioInputProcessor])

  return {
    playState,
    start,
    stop,
    pause,
  }
}

export default useTranscriptProcessor
