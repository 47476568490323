import type { GroupRole } from "../groups/types.js"
import type { NotificationPreferences } from "../notifications/types.js"

export const USERS_COLLECTION = "users"
export const USER_TOKENS_EXPIRY_COLLECTION = "user_tokens_expiry"

export interface UserLoginData {
  displayName?: string
  photoURL?: string
  metadata?: unknown
}

export interface User {
  oid: string
  uid: string
  email: string
  displayName: string
  photoURL: string
  metadata: {
    // TODO(mgraczyk): Convert these to timestamps or dates.
    creationTime?: string
    lastSignInTime?: string
  }
  roles: {
    [groupOid: string]: GroupRole
  }

  // Group to use when user logs in.
  default_group_oid: string

  notificationPreferences?: NotificationPreferences
  banned?: boolean
  created_at?: FirebaseFirestore.Timestamp | null
}
