import App from "antd/es/app"
import Button from "antd/es/button"
import Empty from "antd/es/empty"
import Skeleton from "antd/es/skeleton"
import Switch from "antd/es/switch"
import Tooltip from "antd/es/tooltip"
import { ArrowUpToLineIcon, MoveUpIcon } from "lucide-react"
import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react"

import emptyEventsImage from "../assets/img/empty_icons/source_document.svg"
import { sortByCreatedAtAsc } from "../utils"
import CardDetailContent from "./CardDetailContent"
import LiveCard from "./LiveCard"
import type { Settings } from "./LiveSettingsButton"
import { DEFAULT_SETTINGS } from "./LiveSettingsButton"
import ProgressSpinner from "./ProgressSpinner"
import { scrollToAlignTop } from "./scrollUtils"
import type { CardDetail, LiveUICard } from "./types"

// Returns true if the element is at least partially above the container.
function isAboveContainer(container: HTMLElement, el: HTMLElement): boolean {
  const elemRect = el.getBoundingClientRect()
  const containerRect = container.getBoundingClientRect()

  // TODO(mgraczyk): For some reason there is a 0.4921875px difference
  return elemRect.top + 0.5 < containerRect.top
}

const PENDING_TIMEOUT = 20_000

const fixCardStates = (
  cards: LiveUICard[],
  expirationCheckTime: number,
  setExpirationCheckTime: (t: number) => void,
): LiveUICard[] => {
  // TODO(mgraczyk): Sort input to avoid sorting here.
  // TODO(mgraczyk): Avoid copying if not needed to avoid rerendering the list.
  const result = cards.filter((c) => !c.dismissed).toSorted(sortByCreatedAtAsc)
  let nextExpirationCheckTime = null
  for (const card of result) {
    if (card.kind === "STATIC_CONTENT") {
      continue
    }

    if (card.answer_state === "PENDING") {
      const createdAtMillis = card.created_at.toMillis()
      const expirationTime = createdAtMillis + PENDING_TIMEOUT
      if (expirationCheckTime >= expirationTime) {
        card.answer_state = "ERROR"
        card.error_message = "Answer took too long"
      } else {
        nextExpirationCheckTime = Math.min(
          nextExpirationCheckTime ?? expirationTime,
          expirationTime,
        )
      }
    }
  }

  if (nextExpirationCheckTime !== null) {
    // Need to check expiration again in the future.
    setTimeout(
      () => setExpirationCheckTime(Date.now()),
      // Add some buffer to make sure we catch the timeout.
      nextExpirationCheckTime - Date.now() + 20,
    )
  }

  return result
}

interface CardsListContainerProps {
  cards: LiveUICard[]
  containerRef: React.LegacyRef<HTMLDivElement>
  cardElements: (HTMLElement | null)[]
  onDismissCard: (card: LiveUICard) => Promise<void>
  onTogglePinCard: (card: LiveUICard) => Promise<void>
  onMarkCardSeen: (card: LiveUICard) => Promise<void>
  onScroll: () => void
  showCardDetail: (cardDetail: CardDetail) => void
  settings: Settings
  callOid: string
}

const CardListContainer = memo(function CardListContainer({
  cards,
  containerRef,
  cardElements,
  onDismissCard,
  onTogglePinCard,
  onMarkCardSeen,
  onScroll,
  showCardDetail,
  settings,
  callOid,
}: CardsListContainerProps) {
  let inner: React.JSX.Element | React.JSX.Element[]

  const pinned = cards.filter((c) => !!c.pinned)
  const topCards = pinned.map((card) => (
    <LiveCard
      key={card.oid}
      message={card}
      onDismissCard={onDismissCard}
      onTogglePinCard={onTogglePinCard}
      onMarkCardSeen={onMarkCardSeen}
      showCardDetail={showCardDetail}
      settings={settings}
      callOid={callOid}
    />
  ))
  if (cards.length === 0) {
    inner = (
      <Empty
        image={emptyEventsImage}
        description="No cards yet, start the call and they will appear as people speak."
        className="m-auto flex flex-col items-center"
      />
    )
  } else {
    // Add mb-auto to first (visually last) card so that it goes to the top.
    inner = (
      <>
        {cards.map((card, i) => (
          <LiveCard
            ref={(node) => {
              cardElements[i] = node
            }}
            key={card.oid}
            className={i === 0 ? "mb-auto" : ""}
            message={card}
            onDismissCard={onDismissCard}
            onTogglePinCard={onTogglePinCard}
            onMarkCardSeen={onMarkCardSeen}
            showCardDetail={showCardDetail}
            settings={settings}
            callOid={callOid}
          />
        ))}
      </>
    )
  }

  return (
    <>
      <div className="sm:hidden">{topCards}</div>
      <div
        className="overflow-anchor-none bg-gray-25 relative flex w-full grow flex-col-reverse items-start overflow-y-scroll rounded border sm:px-2"
        ref={containerRef}
        onScroll={onScroll}
      >
        {inner}
      </div>
    </>
  )
})

interface Props {
  cards: LiveUICard[]
  className?: string
  loading?: boolean
  settings: Settings
  onDismissCard: (card: LiveUICard) => Promise<void>
  onTogglePinCard: (card: LiveUICard) => Promise<void>
  onMarkCardSeen: (card: LiveUICard) => Promise<void>
  callOid: string
}

const LiveFeed: React.FC<Props> = ({
  cards,
  className = "",
  loading = false,
  settings,
  onDismissCard,
  onTogglePinCard,
  onMarkCardSeen,
  callOid,
}) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const cardsRef = useRef<(HTMLElement | null)[]>([])
  const [autoScrollOn, setAutoScrollOn] = useState<boolean>(false)
  const autoScrollTimeout = useRef<number | null>(null)
  const [spinnerGeneration, setSpinnerGeneration] = useState<number>(0)
  const { modal } = App.useApp()

  const [expirationCheckTime, setExpirationCheckTime] = useState<number>(
    Date.now(),
  )
  const [numCardsAbove, setNumCardsAbove] = useState<number>()
  const fixedCards = useMemo(
    () => fixCardStates(cards, expirationCheckTime, setExpirationCheckTime),
    [cards, expirationCheckTime],
  )

  const autoscrollTimeoutSeconds =
    settings.autoscrollTimeoutSeconds ??
    DEFAULT_SETTINGS.autoscrollTimeoutSeconds

  const startAutoscroll = useCallback(() => {
    const doScroll = (skipScroll: boolean) => {
      if (!skipScroll) {
        goToNext()
      }
      // Bump generation to restart the spinner UI.
      setSpinnerGeneration((prev) => prev + 1)
      autoScrollTimeout.current = setTimeout(
        () => doScroll(false),
        autoscrollTimeoutSeconds * 1000,
        // NOTE: Cast required because we are using nodejs types in frontend.
      ) as unknown as number
    }
    doScroll(true)
  }, [autoscrollTimeoutSeconds])

  const updateNumCardsAbove = useCallback(() => {
    const container = containerRef.current
    if (!container) {
      return
    }

    const cards = cardsRef.current
    let numAbove = 0
    for (const el of cards) {
      if (!el) {
        continue
      }
      if (el.nodeType !== Node.ELEMENT_NODE) {
        continue
      }

      if (isAboveContainer(container, el)) {
        numAbove++
      }
    }

    // Update autoscroll.
    if (numAbove === 0) {
      cancelPendingAutoScroll()
    } else if (autoScrollOn && !autoScrollTimeout.current) {
      startAutoscroll()
    }
    setNumCardsAbove(numAbove)
  }, [autoScrollOn, startAutoscroll])

  // TODO(mgraczyk): Multiple clicks do not work, need to use a ref or pass
  // "next" state to callback.
  const goToNext = () => {
    const container = containerRef.current
    if (!container) {
      return
    }
    const cards = cardsRef.current

    // Find the first card that is above the container.
    for (let i = 0; i < cards.length; ++i) {
      const el = cards[i]
      if (!el) {
        continue
      }
      if (el.nodeType !== Node.ELEMENT_NODE) {
        continue
      }

      if (isAboveContainer(container, el)) {
        scrollToAlignTop(container, el)
        break
      }
    }
  }

  const cancelPendingAutoScroll = () => {
    if (autoScrollTimeout.current) {
      clearTimeout(autoScrollTimeout.current)
      autoScrollTimeout.current = null
    }
  }

  const restartPendingAutoScroll = () => {
    // TODO(mgraczyk): Need to restart when cards change, but only if there are
    // new cards that aren't visible.
    if (autoScrollTimeout.current) {
      clearTimeout(autoScrollTimeout.current)
      autoScrollTimeout.current = null
    }
  }

  const onScroll = () => {
    restartPendingAutoScroll()
    updateNumCardsAbove()
  }

  const onChangeAutoScroll = useCallback(
    (enabled: boolean) => {
      cancelPendingAutoScroll()
      setAutoScrollOn(enabled)
      if (enabled && !!numCardsAbove) {
        startAutoscroll()
      }
    },
    [numCardsAbove, startAutoscroll],
  )

  const goToTop = () => {
    const container = containerRef.current
    if (!container) {
      return
    }
    // Negative because scroll is reversed.
    container.scrollTop = -container.scrollHeight
  }

  useEffect(updateNumCardsAbove, [updateNumCardsAbove, startAutoscroll, cards])

  useEffect(() => {
    // TODO(mgraczyk): Remove this hack, start at top with CSS.
    if (!loading) {
      setTimeout(goToTop, 20)
    }
  }, [loading])

  const showCardDetail = useCallback(
    async (cardDetail: CardDetail) => {
      await modal.info({
        title: cardDetail.question,
        content: <CardDetailContent cardDetail={cardDetail} />,
        centered: true,
        width: "90%",
        type: "info",
        maskClosable: true,
        closable: true,
        footer: null,
      })
    },
    [modal],
  )

  const onTogglePinCardFeedWrapper = useCallback(
    (card: LiveUICard) => {
      if (!card.pinned) {
        onChangeAutoScroll(false)
      }
      return onTogglePinCard(card)
    },
    [onChangeAutoScroll, onTogglePinCard],
  )

  const hasNewerCards = !!numCardsAbove
  const newerCardsLabel = (
    <span
      className={
        "w-20 grow text-right text-sm text-gray-800 " +
        (hasNewerCards ? "font-semibold" : "")
      }
    >
      {numCardsAbove === undefined
        ? "Loading..."
        : hasNewerCards
          ? `${numCardsAbove} more cards`
          : "No more cards"}
    </span>
  )
  const autoScrollSwitch = (
    <div className="flex items-center gap-2">
      <Tooltip title="Automatically scroll to new messages">
        <Switch
          checkedChildren="Autoscroll (on)"
          unCheckedChildren="Autoscroll (off)"
          value={autoScrollOn}
          onChange={onChangeAutoScroll}
          disabled={loading}
        />
      </Tooltip>
      <ProgressSpinner
        key={spinnerGeneration}
        className="h-8 w-8"
        on={!!autoScrollTimeout.current}
        seconds={autoscrollTimeoutSeconds}
      />
    </div>
  )

  return (
    <div className={"flex w-full flex-col " + className}>
      <div className="my-2 flex h-8 items-center gap-2 max-sm:mt-2">
        <h3 className="">Feed</h3>
        <span className="grow" />
        {autoScrollSwitch}
        <Tooltip title="Go to top">
          <Button
            onClick={goToTop}
            icon={<ArrowUpToLineIcon />}
            disabled={loading || numCardsAbove === 0}
            className="gap-0 p-1"
          />
        </Tooltip>
        <Tooltip title="Go to next card">
          <Button
            icon={<MoveUpIcon />}
            onClick={goToNext}
            disabled={loading || numCardsAbove === 0}
            className={"pl-1 " + (hasNewerCards ? "" : "")}
          >
            {newerCardsLabel}
          </Button>
        </Tooltip>
      </div>
      <Skeleton active loading={loading}>
        <CardListContainer
          cards={fixedCards}
          containerRef={containerRef}
          onScroll={onScroll}
          cardElements={cardsRef.current}
          onDismissCard={onDismissCard}
          onTogglePinCard={onTogglePinCardFeedWrapper}
          onMarkCardSeen={onMarkCardSeen}
          showCardDetail={showCardDetail}
          settings={settings}
          callOid={callOid}
        />
      </Skeleton>
    </div>
  )
}

export default LiveFeed
