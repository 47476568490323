import { collection, onSnapshot } from "firebase/firestore"
import { useEffect, useState } from "react"

import { useActiveUserAuthorizationFromContext } from "../contexts/ActiveUserAuthorizationContext"
import { makeConverter } from "../dbUtils"
import { db } from "../firebaseApp"
import type { GroupTag } from "../tags/types"
import { GROUPS_COLLECTION, GROUP_TAGS_SUBCOLLECTION } from "../types/common"

type ResultType = [GroupTag[], boolean, Error | undefined]

export const useGroupTags = (group_oid: string): ResultType => {
  const [tags, setTags] = useState<GroupTag[]>([])
  const [loading, setLoading] = useState<boolean>(true)
  const [error, setError] = useState<Error>()

  useEffect(() => {
    const tagRef = collection(
      db,
      GROUPS_COLLECTION,
      group_oid,
      GROUP_TAGS_SUBCOLLECTION,
    ).withConverter(makeConverter<GroupTag>())
    setLoading(true)
    return onSnapshot(
      tagRef,
      (querySnapshot) => {
        setLoading(false)
        setTags(querySnapshot.docs.map((doc) => doc.data()))
      },
      (error) => {
        console.error("error fetching group tags: ", error)
        setLoading(false)
        setError(error)
      },
    )
  }, [group_oid])

  return [tags, loading, error]
}

export const useActiveGroupTags = (): ResultType => {
  const { activeGroupOid } = useActiveUserAuthorizationFromContext()
  return useGroupTags(activeGroupOid)
}
