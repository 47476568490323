import { httpsCallable } from "../serverUtils"
import {
  type CreateGroupTagArgs,
  type EditGroupTagArgs,
  type RemoveGroupTagArgs,
} from "../tags/types"
import type {
  CreateGroupSSOConfigRequest,
  DeleteGroupSSOConfigRequest,
  GetSSOConfigRequest,
  GetSSOConfigResponse,
} from "./signin/types.js"
import type {
  CreateGroupArgs,
  CreateGroupInviteArgs,
  CreateGroupInviteResult,
  QuiltAdminAddGroupMemberArgs,
  QuiltAdminUpdateGroupArgs,
  RemoveGroupMemberArgs,
  SwitchGroupArgs,
  UpdateGroupArgs,
  UpdateGroupMemberArgs,
} from "./types"

export const createGroupInvite = httpsCallable<
  CreateGroupInviteArgs,
  CreateGroupInviteResult
>("createGroupInvite")

export const acceptGroupInvite = httpsCallable<{
  oid: string
}>("acceptGroupInvite")

export const removeGroupInvite = httpsCallable<{
  oid: string
}>("removeGroupInvite")

export const updateUserGroupRole = httpsCallable<UpdateGroupMemberArgs>(
  "updateUserGroupRole",
)

export const removeGroupMember =
  httpsCallable<RemoveGroupMemberArgs>("removeGroupMember")

export const updateGroup = httpsCallable<UpdateGroupArgs>("updateGroup")

export const deleteGroupSSO =
  httpsCallable<DeleteGroupSSOConfigRequest>("deleteGroupSSO")
export const createGroupSSO =
  httpsCallable<CreateGroupSSOConfigRequest>("createGroupSSO")
export const getSSOConfig = httpsCallable<
  GetSSOConfigRequest,
  GetSSOConfigResponse
>("getSSOConfig", { authNotRequired: true })

// Admin API.
// Can only be called by Quilt admins.
export const adminCreateGroup = httpsCallable<CreateGroupArgs, { oid: string }>(
  "adminCreateGroup",
)
export const adminUpdateGroup =
  httpsCallable<QuiltAdminUpdateGroupArgs>("adminUpdateGroup")
export const adminAddGroupMember = httpsCallable<QuiltAdminAddGroupMemberArgs>(
  "adminAddGroupMember",
)

export const adminCreateGroupTag = httpsCallable<CreateGroupTagArgs>(
  "adminCreateGroupTag",
)

export const adminRemoveGroupTag = httpsCallable<RemoveGroupTagArgs>(
  "adminRemoveGroupTag",
)

export const adminUpdateGroupTag = httpsCallable<EditGroupTagArgs>(
  "adminUpdateGroupTag",
)

export const switchGroupApi = httpsCallable<SwitchGroupArgs>("switchGroup")
