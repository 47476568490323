import Select from "antd/es/select"
import Skeleton from "antd/es/skeleton"
import { useMemo } from "react"

import { useActiveGroupTags } from "../hooks/useGroupTags"
import { TagListItem } from "../tags/Tag"

interface Props {
  onChange?: (tagOids: string[]) => void
  value?: string[]
  id?: string
  className?: string
}

const SelectTags: React.FC<Props> = ({
  onChange,
  value,
  id,
  className = "w-full",
}) => {
  const [tags, tagsLoading] = useActiveGroupTags()

  const options = useMemo(() => {
    return tags?.map((tag) => ({
      value: tag.oid,
      label: <TagListItem tag={tag} />,
      name: tag.name,
    }))
  }, [tags])

  return (
    <Select
      id={id}
      mode="multiple"
      className={className}
      options={options}
      optionFilterProp="name"
      onChange={onChange}
      value={value}
      placeholder={
        <Skeleton
          active
          loading={tagsLoading}
          title={false}
          paragraph={{ rows: 1 }}
        >
          Select Tags
        </Skeleton>
      }
      disabled={tagsLoading}
    />
  )
}

export default SelectTags
