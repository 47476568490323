import { Input, Skeleton } from "antd"
import { collection, limit, orderBy, query, where } from "firebase/firestore"
import { SearchIcon } from "lucide-react"
import { useState } from "react"
import { useCollectionData } from "react-firebase-hooks/firestore"
import { Link } from "react-router-dom"

import GongLogo from "../../assets/img/gong_logo.svg"
import QuiltLogo from "../../assets/img/logo_only.svg"
import Header from "../../components/Header"
import { useActiveUserAuthorizationFromContext } from "../../contexts/ActiveUserAuthorizationContext"
import { makeConverter } from "../../dbUtils"
import { db } from "../../firebaseApp"
import { CALL_OUTPUTS_SUBCOLLECTION } from "../../live/playbook/db"
import type { CallOutput } from "../../live/playbook/types"
import { GROUPS_COLLECTION } from "../../types/common"
import { toHumanReadableString } from "../../utils"

const SkeletonCard = () => (
  <div className="h-[200px] rounded-lg border border-gray-200 bg-white p-6 shadow-sm">
    <div className="mb-4 flex items-start justify-between">
      <div className="flex items-center gap-3">
        <Skeleton.Avatar active size="large" />
        <Skeleton.Input active size="small" />
      </div>
      <Skeleton.Button active size="small" />
    </div>
    <div className="space-y-3">
      <Skeleton.Input active block size="small" />
      <Skeleton.Input active block size="small" />
      <Skeleton.Input active block size="small" />
    </div>
  </div>
)

export const StatusBadge = ({
  isDone,
  className,
}: {
  isDone?: boolean
  className?: string
}) => {
  if (isDone === undefined) {
    return null
  }
  const statusClassName = isDone
    ? "bg-green-50 text-green-700 ring-green-600/20"
    : "bg-red-50 text-red-700 ring-red-600/20"

  return (
    <div
      className={`${className} rounded-md px-2 py-1 text-sm font-medium ring-1 ring-inset ${statusClassName}`}
    >
      {isDone ? "Done" : "Processing"}
    </div>
  )
}

const CallCard = ({ call }: { call: CallOutput }) => {
  const isQuiltSource = call.source_kind === "LIVE_ASSISTED_CALL"
  const isDone = call.playbook_output?.done_processing

  return (
    <Link to={`/calls/${call.oid}`}>
      <div className="rounded-lg border border-gray-200 bg-white p-6 shadow-sm transition-shadow hover:shadow-md">
        <div className="mb-4 flex items-start justify-between">
          <div className="flex items-center gap-3">
            <div className="h-8 w-8">
              <img
                src={isQuiltSource ? QuiltLogo : GongLogo}
                alt={isQuiltSource ? "Quilt" : "Gong"}
                className="mr-4 h-full w-full"
              />
            </div>
            <div className="text-lg font-medium text-gray-900">
              {isQuiltSource ? "Quilt" : "Gong"} Call
            </div>
          </div>
          <StatusBadge isDone={isDone} />
        </div>
        <div className="text-sm text-gray-600">
          <div>Added: {toHumanReadableString(call.created_at)}</div>
          <div>Created by: {call.created_by.email}</div>
        </div>
      </div>
    </Link>
  )
}

const CallOutputsPage = () => {
  const [searchText, setSearchText] = useState("")
  const { activeGroupOid } = useActiveUserAuthorizationFromContext()

  const [callOutputs, callOutputsLoading, callOutputsLoadingError] =
    useCollectionData<CallOutput>(
      query(
        collection(
          db,
          GROUPS_COLLECTION,
          activeGroupOid,
          CALL_OUTPUTS_SUBCOLLECTION,
        ),
        where("ui_visible", "==", true),
        orderBy("created_at", "desc"),
        limit(50),
      ).withConverter(makeConverter<CallOutput>()),
    )

  const filteredData =
    callOutputs?.filter(
      (call) =>
        call.external_id.toLowerCase().includes(searchText.toLowerCase()) ||
        call.source_kind.toLowerCase().includes(searchText.toLowerCase()) ||
        call.created_by.email.toLowerCase().includes(searchText.toLowerCase()),
    ) ?? []

  if (callOutputsLoadingError) {
    return (
      <div>Error loading call outputs: {callOutputsLoadingError.message}</div>
    )
  }

  return (
    <>
      <Header
        title="Calls"
        breadcrumbs={[{ title: "Calls", href: "/calls" }]}
      />

      <div className="flex grow flex-col overflow-y-hidden">
        <div className="flex grow flex-col overflow-y-auto p-8">
          <div className="mb-2 flex gap-2">
            <Input
              placeholder="Search calls..."
              prefix={<SearchIcon size={15} className="mr-1 text-gray-400" />}
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              className="w-full"
            />
          </div>
          <div className="mb-4 font-bold text-gray-600">
            Showing {filteredData.length} of {callOutputs?.length ?? 0} items
          </div>
          <div className="grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-3">
            {callOutputsLoading ? (
              <>
                <SkeletonCard />
                <SkeletonCard />
                <SkeletonCard />
              </>
            ) : (
              filteredData.map((call) => (
                <CallCard key={call.oid} call={call} />
              ))
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default CallOutputsPage
