import { functionsAxiosInstance } from "../../api"
import type { OauthStateParam } from "../../auth/api"
import {
  GONG_PROVIDER,
  SALESFORCE_PROVIDER,
  ZOOM_PROVIDER,
} from "../../credentials/providerTypes"
import { openUrlInPopup } from "../../windowUtils"

interface OAuthProviderInfo {
  baseUrl: string
  redirectSuffix: string
  client_id?: string
  extraParams?: Record<string, string>
  requiresPkce?: boolean
}

const OAUTH_PROVIDER_MAPPING: Record<string, OAuthProviderInfo> = {
  [GONG_PROVIDER]: {
    baseUrl: "https://app.gong.io/oauth2/authorize",
    redirectSuffix: "/v1/gongAuthCallback",
    client_id: import.meta.env.VITE_GONG_OAUTH_CLIENT_ID,
  },
  [SALESFORCE_PROVIDER]: {
    baseUrl: "https://login.salesforce.com/services/oauth2/authorize",
    redirectSuffix: "/v1/salesforceAuthCallback",
    client_id: import.meta.env.VITE_SALESFORCE_OAUTH_CLIENT_ID,
    requiresPkce: true,
  },
  [ZOOM_PROVIDER]: {
    baseUrl: "https://zoom.us/oauth/authorize",
    redirectSuffix: "/v1/zoomAuthCallback",
    client_id: import.meta.env.VITE_ZOOM_OAUTH_CLIENT_ID,
  },
}

const getOauthUrl = (
  provider: keyof typeof OAUTH_PROVIDER_MAPPING,
  stateParam: OauthStateParam,
): string => {
  const { baseUrl, redirectSuffix, client_id, requiresPkce } =
    OAUTH_PROVIDER_MAPPING[provider]

  if (!client_id) {
    throw new Error(`Missing ${provider} client id`)
  }

  const redirectUri = `${functionsAxiosInstance.defaults.baseURL}${redirectSuffix}`
  const params = new URLSearchParams({
    client_id,
    response_type: "code",
    owner: "user",
    redirect_uri: redirectUri,
    state: stateParam.state,
    ...(stateParam.code_challenge && requiresPkce
      ? {
          code_challenge_method: stateParam.code_challenge_method,
          code_challenge: stateParam.code_challenge,
        }
      : {}),
    ...(OAUTH_PROVIDER_MAPPING[provider].extraParams ?? {}),
  })

  return `${baseUrl}?${params.toString()}`
}

export type OAUTH_PROVIDERS = keyof typeof OAUTH_PROVIDER_MAPPING

export async function doOauthFlow(
  provider: OAUTH_PROVIDERS,
  stateParam: OauthStateParam,
): Promise<void> {
  const url = getOauthUrl(provider, stateParam)
  await openUrlInPopup(
    url,
    `${provider[0].toUpperCase()}${provider.slice(1)} OAuth`,
  )
}
