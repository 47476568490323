import Modal from "antd/es/modal"
import { CircleAlert } from "lucide-react"
import { useCallback, useMemo, useReducer, useState } from "react"
import { read as readExcel } from "xlsx"

import type { FileData } from "../../documents/types"
import { EXCEL_SUPPORTED_MIMETYPES } from "../../types/mimetype"
import MapFields from "./MapFields"
import Progress from "./Progress"
import WizardContext from "./context"
import { reducer } from "./reducer"
import {
  initialDocumentSheetState,
  initialFileDocumentState,
  initialState,
} from "./state"
import type { FileDocument, GoogleDriveDocument } from "./state"
import { getDataFromWorkbook } from "./utils"

interface QuestionnaireWorkflowFileWizardProps {
  onClose: () => void
  handleDocumentProcessing: (
    document: FileDocument | GoogleDriveDocument,
  ) => Promise<void>
  files: FileData[]
  isCompletedQuestionnaire: boolean
}

export const QuestionnaireWorkflowWizardFromFiles: React.FC<
  QuestionnaireWorkflowFileWizardProps
> = ({
  files,
  onClose,
  handleDocumentProcessing,
  isCompletedQuestionnaire,
}) => {
  const documents = useMemo(
    () =>
      files.map((file) => {
        const { fileContents, fileType, fileName } = file
        if (EXCEL_SUPPORTED_MIMETYPES.includes(file.fileType)) {
          const workbook = readExcel(file.fileContents, {
            sheetRows: 200,
            raw: false,
          })
          const workbookData = getDataFromWorkbook(
            workbook,
            /*extraEmptyCols=*/ isCompletedQuestionnaire ? 0 : 1,
          )
          return {
            ...initialFileDocumentState,
            mimeType: fileType,
            fileContents,
            fileType,
            fileName,
            workbookData,
            sheets: workbookData.sheetNames.map((sheetName) => ({
              ...initialDocumentSheetState,
              sheetName,
            })),
          }
        } else {
          throw new Error("Unsupported file type: " + fileType)
        }
      }),
    [files, isCompletedQuestionnaire],
  )
  return (
    <QuestionnaireWorkflowWizard
      onClose={onClose}
      documents={documents}
      handleDocumentProcessing={handleDocumentProcessing}
      isCompletedQuestionnaire={isCompletedQuestionnaire}
    />
  )
}

const ConfirmModalContent: React.FC = () => (
  <div className="flex flex-col items-center">
    <div className="bg-gray-25 my-auto grid size-20 place-content-center rounded-full">
      <CircleAlert size={32} />
    </div>
    <h1 className="my-2 text-xl font-bold text-gray-800">
      Exit Without Saving?
    </h1>
    <p className="my-2 text-gray-800">
      The current document will not be processed.
    </p>
  </div>
)

interface QuestionnaireWorkflowWizardProps {
  onClose: () => void
  documents: (FileDocument | GoogleDriveDocument)[]
  handleDocumentProcessing: (
    document: FileDocument | GoogleDriveDocument,
  ) => Promise<void>
  isCompletedQuestionnaire: boolean
}

const QuestionnaireWorkflowWizard: React.FC<
  QuestionnaireWorkflowWizardProps
> = ({
  documents,
  onClose: onParentClose,
  handleDocumentProcessing,
  isCompletedQuestionnaire,
}) => {
  const populatedInitialState = {
    ...initialState,
    documents,
  }
  const [state, dispatch] = useReducer(reducer, populatedInitialState)

  const [modal, contextHolder] = Modal.useModal()
  const [open, setOpen] = useState(true)

  const onClose = useCallback(() => {
    onParentClose()
    setOpen(false)
  }, [onParentClose])

  const onCancel = useCallback(() => {
    return modal.confirm({
      title: false,
      icon: <></>,
      content: <ConfirmModalContent />,
      onOk: onClose,
    })
  }, [modal, onClose])

  return (
    <WizardContext.Provider value={{ state, dispatch }}>
      {contextHolder}
      <Modal
        open={open}
        footer={false}
        centered={true}
        width="95%"
        destroyOnClose={true}
        closeIcon={false}
        maskClosable={false}
        onCancel={onCancel}
        classNames={{
          body: "flex flex-col h-[90vh]",
        }}
      >
        <Progress />
        <MapFields
          hideResponseControls={isCompletedQuestionnaire}
          onCancel={onCancel}
          onClose={onClose}
          handleDocumentProcessing={handleDocumentProcessing}
        />
      </Modal>
    </WizardContext.Provider>
  )
}

export default QuestionnaireWorkflowWizard
