import Button from "antd/es/button"
import Popover from "antd/es/popover"
import Select from "antd/es/select"
import Skeleton from "antd/es/skeleton"
import { VoteIcon } from "lucide-react"
import { useCallback, useState } from "react"

import { useActiveUserAuthorizationFromContext } from "../contexts/ActiveUserAuthorizationContext"
import { useKeydown } from "../hooks/events"
import useErrorPopup from "../hooks/useErrorPopup"
import { useGroupMembers } from "../hooks/useGroupMembers"
import { assignQuestionnaireJobAnswers } from "../pages/QuestionnaireAssistant/api"
import type { AnswerActor } from "../types/jobs"

interface AssignFormProps {
  onClose: () => void
  jobOid: string
  answerOids: string[]
  assignedTo?: AnswerActor | null
  isSubmitting: boolean
  setSubmitting: (value: boolean) => void
}

const AssignQuestionnaireAnswerForm: React.FC<AssignFormProps> = ({
  onClose,
  jobOid,
  answerOids,
  assignedTo,
  isSubmitting,
  setSubmitting,
}) => {
  const [selectedMember, setSelectedMember] = useState<string | undefined>(
    assignedTo?.uid ?? "",
  )
  const { activeGroupOid } = useActiveUserAuthorizationFromContext()
  const [members, loading] = useGroupMembers(activeGroupOid)
  const { handleSuccess, handleError } = useErrorPopup()

  useKeydown(27 /*escape*/, onClose)

  const member = members?.find((m) => m.uid === selectedMember)

  const onSubmit = useCallback(
    async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault()
      e.stopPropagation()
      if (!selectedMember || !member) {
        return
      }

      setSubmitting(true)
      try {
        await assignQuestionnaireJobAnswers(jobOid, answerOids, {
          uid: member.uid,
          email: member.email,
        })
        handleSuccess("Assigned the questionnaire answer")
        onClose()
      } catch (error) {
        handleError({
          error,
          prefix: "Couldn't assign questionnaire answer",
        })
      } finally {
        setSubmitting(false)
      }
    },
    [
      handleError,
      handleSuccess,
      selectedMember,
      onClose,
      jobOid,
      answerOids,
      member,
      setSubmitting,
    ],
  )

  if (loading) {
    return <Skeleton.Input active className="block h-4 w-full" />
  }

  return (
    <form className="flex w-[400px] text-sm" action="" onSubmit={onSubmit}>
      <Select
        placeholder="Select the new owner..."
        value={selectedMember}
        onChange={setSelectedMember}
        showSearch
        className="w-full"
        optionFilterProp="label"
        options={members?.map((member) => ({
          label: member.email,
          value: member.uid,
        }))}
      />
      <Button
        disabled={
          isSubmitting || !selectedMember || selectedMember === assignedTo?.uid
        }
        className="ml-2"
        type="primary"
        htmlType="submit"
      >
        {isSubmitting ? "Change..." : "Change"}
      </Button>
    </form>
  )
}

interface ButtonProps {
  jobOid: string
  answerOids: string[]
  assignedTo?: AnswerActor | null
  small?: boolean
  disabled?: boolean
}

const AssignQuestionnaireAnswerButton: React.FC<ButtonProps> = ({
  jobOid,
  answerOids,
  assignedTo,
  disabled,
  small,
}) => {
  const [open, setOpen] = useState(false)
  const hide = useCallback(() => {
    setOpen(false)
  }, [setOpen])
  const [isSubmitting, setSubmitting] = useState<boolean>(false)

  return (
    <Popover
      content={
        <AssignQuestionnaireAnswerForm
          jobOid={jobOid}
          answerOids={answerOids}
          onClose={hide}
          assignedTo={assignedTo}
          isSubmitting={isSubmitting}
          setSubmitting={setSubmitting}
        />
      }
      trigger="click"
      placement="bottomLeft"
      open={open}
      onOpenChange={setOpen}
    >
      <Button
        icon={<VoteIcon />}
        title="Change Owner"
        className="flex items-center justify-center"
        disabled={disabled || isSubmitting || answerOids.length === 0}
        loading={isSubmitting}
        size={small ? "small" : "middle"}
      >
        <span>{isSubmitting ? "Changing owner..." : "Change Owner"}</span>
      </Button>
    </Popover>
  )
}

export default AssignQuestionnaireAnswerButton
