import Empty from "antd/es/empty"

import emptyEventsImage from "../assets/img/empty_icons/source_document.svg"
import LiveCard from "./LiveCard"
import type { LiveUICard } from "./types"

interface Props {
  className?: string
  cards: LiveUICard[]
  onDismissCard: (card: LiveUICard) => Promise<void>
  onTogglePinCard: (card: LiveUICard) => Promise<void>
  onMarkCardSeen: (card: LiveUICard) => Promise<void>
}

const ShortCardsList: React.FC<Props> = ({
  className = "",
  cards,
  onDismissCard,
  onTogglePinCard,
  onMarkCardSeen,
}) => {
  let inner
  if (cards.length === 0) {
    inner = (
      <Empty
        image={emptyEventsImage}
        description="No starred yet, star some in the Feed to keep them handy here."
        className="m-auto flex flex-col items-center"
      />
    )
  } else {
    // Add mb-auto to first (visually last) card so that it goes to the top.
    inner = cards.map((card, i) => (
      <LiveCard
        key={card.oid}
        className={i === 0 ? "mb-auto" : ""}
        message={card}
        onDismissCard={onDismissCard}
        onTogglePinCard={onTogglePinCard}
        onMarkCardSeen={onMarkCardSeen}
      />
    ))
  }

  return (
    <div
      className={
        "overflow-anchor-none flex grow flex-col-reverse overflow-y-scroll px-2 " +
        className
      }
    >
      {inner}
    </div>
  )
}

export default ShortCardsList
