import { useEffect } from "react"

import { sendAnalyticsEvent } from "../analytics"

const handleNavigationEvent = (url: string) => {
  const parsedUrl = new URL(url)
  // TODO(mgraczyk): Remove sensitive info (token) from url?
  sendAnalyticsEvent({
    event_type: "PAGEVIEW",
    surface: "WEB_ANY",
    event_data: {
      url,
      path: parsedUrl.pathname,
    },
  })
}

const PageViewObserver: React.FC = () => {
  let prevUrl = ""

  useEffect(() => {
    const navigation = window.navigation
    if (!navigation) {
      return
    }

    const callback = (event: NavigationEvent) => {
      if (prevUrl === event.destination.url) {
        return
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
      prevUrl = event.destination.url
      handleNavigationEvent(event.destination.url)
    }

    navigation.addEventListener("navigate", callback)
    callback({
      destination: { sameDocument: false, url: window.location.href },
    })
    return () => navigation.removeEventListener("navigate", callback)
    // NOTE: Do not depend on prevUrl, it is only used to prevent duplicate events
  }, [])
  return null
}

export default PageViewObserver
